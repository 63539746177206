import React, { useState } from 'react';
import { compose } from 'redux';

import { editField } from 'farmerjoe-common/lib/actions/field';
import {
  deleteComment,
  editComment,
} from 'farmerjoe-common/lib/actions/comment';
import { editWaitTime } from 'farmerjoe-common/lib/actions/waitTime';
import { editFertilizing } from 'farmerjoe-common/lib/actions/actions';
import { openAnalysis } from 'farmerjoe-common/lib/actions/analysis';
import { editForm } from 'farmerjoe-common/lib/actions/form';

import * as CommentUtils from '../../../utils/Comment';
import CreateField from '../../Field/CreateField';
import FormBonitur from '../../Bonitur/CreateBoniturForm';
import FormFertilizing from '../../Fertilizers/Form';
import ModalList from '../../Modal/ModalList';
import WaitTimeForm from '../../WaitTime/Form';
import YieldForm from '../../Field/YieldForm';
import withRouter from '../../Router/withRouter';
import { AlertConfirmDialog } from '../../Dialog/Dialog';
import DateTimeForm from './DateTimeForm';

import { COMMENT_TYPE_BONITUR } from '../constants';

import * as constants from '../../../styles/style';
import I18n from '../../../language/i18n';

import * as handlers from './handlers';
import ReduxConnector from './redux-connector';


const DIALOG_CONFIRM_RESULT = 'ok';

type Props = {
  comment: any;
  actions?: {
    editComment: typeof editComment;
    deleteComment: typeof deleteComment;
    editField: typeof editField;
    editWaitTime: typeof editWaitTime;
    editFertilizing: typeof editFertilizing;
    openAnalysis: typeof openAnalysis;
    editForm: typeof editForm;
  };
  trigger?: any;
  myCompanyProfile?: any;
  history?: any;
  openCompany?: any;
};

const getCommentActions = (comment, myCompanyProfile): any[] => {
  const availableActions = [
    {
      condition: CommentUtils.typeIs(COMMENT_TYPE_BONITUR, comment.type),
      label: I18n.t('print'), value: 'onPrint',
    },
    {
      condition: !CommentUtils.typeIs('image', comment.type) && !CommentUtils.typeIs('bonitur', comment.type),
      label: I18n.t('copy'), value: 'onCopy',
    },
    {
      condition: CommentUtils.canEditComment(comment, myCompanyProfile),
      label: I18n.t('edit'), value: 'onEdit',
    },
    {
      condition: CommentUtils.canEditComment(comment, myCompanyProfile) || CommentUtils.typeIs('image', comment.type),
      label: I18n.t('edit_date'), value: 'onEditDate',
    },
    {
      condition: CommentUtils.canDeleteComment(comment, myCompanyProfile),
      label: I18n.t('delete'), value: 'onDelete', labelStyle: { color: constants.FJAPPLERED },
    },
  ];

  return availableActions.filter(action => action.condition).map(({ condition, ...rest }) => rest);
};

const CommentForms = ({ formState, setFormState }) => (
  <>
    {formState.showCreateForm && <CreateField show onClose={() => setFormState({ showCreateForm: false })} />}
    {formState.comment && formState.showWaitTimeForm && <WaitTimeForm comment={formState.comment} show onClose={() => setFormState({ showWaitTimeForm: false, comment: null })} />}
    {formState.comment && formState.showFertilizingForm && <FormFertilizing comment={formState.comment} show onClose={() => setFormState({ showFertilizingForm: false, comment: null })} />}
    {formState.comment && formState.showYieldForm && <YieldForm comment={formState.comment} show onClose={() => setFormState({ showYieldForm: false, comment: null })} />}
    {formState.comment && formState.showBoniturForm && <FormBonitur comment={formState.comment} show onClose={() => setFormState({ showBoniturForm: false, comment: null })} />}
    {formState.comment && formState.showDateTimeForm && <DateTimeForm comment={formState.comment} show onClose={() => setFormState({ showDateTimeForm: false })} />}
  </>
);

const Comment = (props: Props) => {
  const [confirm, setConfirm] = useState<any>(null);
  const [formState, setFormState] = useState({
    showCreateForm: false,
    showWaitTimeForm: false,
    showFertilizingForm: false,
    showYieldForm: false,
    showBoniturForm: false,
    showDateTimeForm: false,
    newDate: null,
    comment: null,
  });

  const updateFormState = (updates) => {
    setFormState((prev) => ({ ...prev, ...updates }));
  };

  if (!props.comment) {
    return null;
  }

  const data = getCommentActions(props.comment, props.myCompanyProfile);

  const callbacks = {
    onPrint: () => handlers.printHandler(props.comment),
    onCopy: () => handlers.copyHandler(props.comment),
    onEdit: () => handlers.editHandler(props.comment, props.actions, {
      showCreateForm: () => updateFormState({ showCreateForm: true }),
      showWaitTimeForm: () => updateFormState({ showWaitTimeForm: true }),
      showFertilizingForm: () => updateFormState({ showFertilizingForm: true }),
      showBoniturForm: () => updateFormState({ showBoniturForm: true }),
      showYieldForm: () => updateFormState({ showYieldForm: true }),
      setComment: (comment) => updateFormState({ comment }),
    }),
    onDelete: () => handlers.deleteHandler(props.comment, (title, message) => {
      setConfirm({
        title,
        children: message,
        buttons: [
          { label: I18n.t('cancel'), value: 'cancel' },
          { label: I18n.t('yes'), value: DIALOG_CONFIRM_RESULT, className: 'btn-danger' },
        ],
        onClose: result => {
          if (result === DIALOG_CONFIRM_RESULT) {
            props.actions?.deleteComment(props.comment);
          }
          setConfirm(null);
        },
      });
    }),
    onEditDate: () => {
      updateFormState({ comment: props.comment });
      updateFormState({ showDateTimeForm: true });
    },
  };

  return (
    <>
      <ModalList
        closeOnClickInside={true}
        dropdownClassName="comment-menu"
        data={data}
        onClick={({ value }) => {
          if (callbacks[value]) {
            callbacks[value]();
          }
        }}
        stopPropagationOnTrigger={true}
        showMenuWhereClicked={true}
        showInPortal={true}>
        {props.trigger}
      </ModalList>

      <CommentForms formState={formState} setFormState={updateFormState} />
      <AlertConfirmDialog
        {...confirm}
        show={!!confirm}
      />
    </>
  );
};

export default compose<typeof Comment>(
  ReduxConnector,
  withRouter,
)(Comment);

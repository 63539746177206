import React from "react";
import "./style.css";
import { get } from "lodash-es";

const StaticImage = ({ config, formik, value = false, error }) => {
  const uri = get(config, "options.image.uriWeb", null);
  const { name: fieldName } = config;
  const { setFieldValue } = formik;

  setFieldValue(fieldName, uri);
  return (
    <div className={"d-flex justify-content-center align-items-center"}>
      <img src={uri} alt="image uri is missing" width="100%" />
    </div>
  );
};
export default React.memo(StaticImage);

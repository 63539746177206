import React from "react";
import * as constants from "../../../styles/style";

const UniqueIdRenderer = (props) => {
  const { value } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}>
      <span
        style={{
          ...constants.styles.strong,
          ...{
            color: constants.FJAPPLEGREEN,
            fontSize: 60,
            marginStart: 10,
          },
        }}>
        {value.substring(0, 3)}
      </span>
      <span
        style={{
          ...constants.styles.strong,
          ...{
            color: constants.FJAPPLEGREEN,
            fontSize: 60,
          },
        }}>
        {value.substring(3, 6)}
      </span>
      <span
        style={{
          ...constants.styles.strong,
          ...{
            color: constants.FJAPPLEGREEN,
            fontSize: 60,
            marginEnd: 10,
          },
        }}>
        {value.substring(6, value.length)}
      </span>
    </div>
  );
};

export default React.memo(UniqueIdRenderer);

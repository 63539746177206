import React, { useState } from "react";
import Icon from "../../Common/Icon";
import { FJNEWGREEN, FJMUTEDDARK } from "../../../styles/style";
import Text from "../../Common/Text";

const RadioRenderer = ({ config, formik, value = false }) => {
  const options = config.options.values;
  const fieldName = config.name;
  const [selected, setSelected] = useState(value ? (value as any) : null);
  const { setFieldValue } = formik;

  return (
    <div className={"fj-form-radio"}>
      {options.map((option) => {
        return option instanceof Object ? (
          <ObjectOption
            key={option.value}
            option={option}
            onClick={() => {
              setFieldValue(fieldName, option.value);
              setSelected(option.value);
            }}
            selected={selected}
          />
        ) : (
          <PlainOption
            key={option}
            value={option}
            onClick={() => {
              setFieldValue(fieldName, option);
              setSelected(option);
            }}
            selected={selected}
          />
        );
      })}
    </div>
  );
};

const PlainOption = ({ value, onClick, selected }) => {
  return (
    <div key={"field-" + value}>
      <div
        onClick={onClick}
        className={"d-flex cursor-pointer"}
      >
        <div
          style={{ width: 40 }}
          className={"d-flex justify-content-center"}
        >
          {value === selected ? (
            <Icon
              name={"ios-checkmark-circle"}
              style={{ fontSize: 25, color: FJNEWGREEN }}
            />
          ) : (
            <Icon
              name={"ios-radio-button-off"}
              style={{ fontSize: 25, color: FJNEWGREEN }}
            />
          )}
        </div>
        <div className={"d-flex"}>
          <Text
            style={{
              paddingTop: 4,
              color: value === selected ? FJNEWGREEN : FJMUTEDDARK,
            }}
          >
            {value}
          </Text>
        </div>
      </div>
    </div>
  );
};

const ObjectOption = ({ option, onClick, selected }) => {
  const { value, label } = option;
  return (
    <div key={"field-" + value}>
      <div
        onClick={onClick}
        className={"d-flex cursor-pointer"}
      >
        <div
          style={{ width: 40 }}
          className={"d-flex justify-content-center"}
        >
          {value === selected ? (
            <Icon
              name={"ios-checkmark-circle"}
              style={{ fontSize: 25, color: FJNEWGREEN }}
            />
          ) : (
            <Icon
              name={"ios-radio-button-off"}
              style={{ fontSize: 25, color: FJNEWGREEN }}
            />
          )}
        </div>
        <div className={"d-flex"}>
          <Text
            style={{
              paddingTop: 4,
              color: value === selected ? FJNEWGREEN : FJMUTEDDARK,
            }}
          >
            {label}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RadioRenderer);

import crypto from "crypto";
import firebase from "firebase/app";
import fb from "../data/firebase";

const saveDeviceInfo = async () => {
  try {
    const userId = fb.auth().currentUser?.uid;
    if (!userId) {
      return;
    }
    const db = fb.firestore();
    const deviceId = generateDeviceId();
    const deviceRef = db.collection("userDevices").doc(userId);

    deviceRef.set({
      [deviceId]: getDeviceInfo(),
    }, { merge: true });
  } catch (error) {
    console.error("Error saving device info:", error);
  }
};
//
// Function to generate a simple hash based on device properties
const generateDeviceId = () => {
  const { userAgent, platform, language } = navigator;
  const uniqueId = getOrCreateDeviceId();
  const rawId = `${uniqueId}-${userAgent}-${platform}-${language}`;
  return fnv1aHash(rawId);
};

const getDeviceInfo = () => {
  return {
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    type: "web",
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    uniqueId: getOrCreateDeviceId(),
  };
};

// https://en.wikipedia.org/wiki/Fowler%E2%80%93Noll%E2%80%93Vo_hash_function
// FNV-1a hash function
const fnv1aHash = (str) => {
  let hash = 0x811c9dc5; // 32-bit FNV offset basis
  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i);
    hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
  }
  return (hash >>> 0).toString(16); // Convert to unsigned hex string
};

const getOrCreateDeviceId = () => {
  let deviceId = getCookie("device_id");
  if (!deviceId) {
    deviceId = crypto.randomBytes(20).toString("hex"); // Generate a unique ID
    setCookie("device_id", deviceId, 365 * 5); // Store for 5 year
  }
  return deviceId;
};

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + "; path=/" + expires;
};

const getCookie = (name) => {
  const nameEQ = name + "=";
  const cookiesArray = document.cookie.split(";");
  for (let i = 0; i < cookiesArray.length; i++) {
    const cookie = cookiesArray[i].trim();
    if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length);
  }
  return null;
};

export default saveDeviceInfo;

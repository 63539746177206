import React from "react";
import DataRow from "./Helpers/DataRow";

const ShareWithRenderer = ({ element: { label, className }, value }) => {
  const companies = value || {};
  const val = Object.keys(companies)
    .map((key) => companies[key].name)
    .join(", ");
  if (!val) {
    return null;
  }
  return  <DataRow className={className} label={"Bonitur geteilt mit"} value={val} valueOnly={false} />;
};

export default ShareWithRenderer;

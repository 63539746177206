import React from "react";
import { Polygon as PolygonComponent } from "@react-google-maps/api";
import { reorderMapEvent } from "../../utils/Map";

type Props = {
  map: google.maps.Map;
  onClick: (...args: Array<any>) => any;
  path: any;
  options: any;
};

const Polygon = ({ map, onClick, ...restProps }: Props) => {
  if (!map) return null;
  return (
    <PolygonComponent
      onClick={reorderMapEvent(map, "click", onClick)}
      {...restProps}
    />
  );
};
export default Polygon;

import React from "react";
import DataRow from "./Helpers/DataRow";
import I18n from "../../../language/i18n";

interface SegmentedControlRendererProps {
  element: {
    label: string;
    options: {
      valueLabel?: string;
      hasSegmentedControlTranslationKey?: boolean;
      segmentedControlOptions?: {
          text: string;
          value: string | number;
          color: "orange" | "yellow" | "green" | "gray" | "red";
      }[];
    };
    segmentedControlOptions: any[];
  };
  value: string | number;
  valueOnly: boolean;
};

const translatedValue = (optionKeys, value) => {
  return optionKeys.filter(option => option.value === value)[0];
};

const SegmentedControlRenderer = (props: SegmentedControlRendererProps) => {
  const { element: { label, options, segmentedControlOptions }, value, valueOnly } = props;
  const { valueLabel, hasSegmentedControlTranslationKey } = options;

  const _value = hasSegmentedControlTranslationKey ? translatedValue(segmentedControlOptions, value) : value;

  if (_value instanceof Object) {
    const { text, color } = _value;
    return <DataRow
      label={label}
      value={I18n.t(text)}
      valueOnly={valueOnly}
      style={{ fontWeight: "normal" }}
      valueStyle={{ color: color }}
    />;
  }
  return <DataRow
    label={label}
    value={_value}
    valueOnly={valueOnly}
    style={{ fontWeight: "normal" }}
  />;
};

export default React.memo(SegmentedControlRenderer);

import React, { useState } from "react";
import Icon from "../../Common/Icon";
import { FJNEWGREEN } from "../../../styles/style";

const SmileyFaceRating = ({ config, formik, value = false, error }) => {
  const { ratings, showNoRating } = config.options;
  const fieldName = config.name;
  const [selected, setSelected] = useState(value ? (value as any).score : null);
  const [noRating, setNoRating] = useState(!value);
  const { setFieldValue } = formik;

  const handleChange = () => {
    setFieldValue(fieldName, undefined);
    setSelected(null);
    setNoRating(!noRating);
  };

  const NoRatingCheckbox = (
    <div className="custom-checkbox-container" style={{ marginTop: 24 }} onClick={handleChange}>
      <input type='checkbox' onChange={handleChange} checked={noRating}/>
      <label style={{ fontWeight: "bold", fontSize: 16, color: FJNEWGREEN }}>Keine Bewertung abgeben</label>
    </div>
  );

  return (
    <>
      <div className={`fj-form-rating ${error ? "fj-form-rating-error" : ""}`}>
        {ratings.map((rating) => {
          const iconColor = rating.color;

          const iconStyle = {
            "--selected-color": rating.score === selected ? iconColor : "#fff",
          } as React.CSSProperties;

          return (
            <div
              key={rating.score}
              className='fj-form-rating-item'
              style={iconStyle}
              onClick={() => {
                setFieldValue(fieldName, rating);
                setSelected(rating.score);
                setNoRating(false);
              }}
            >
              <Icon
                name={rating.smileyIcon}
                iconType={"fal"}
                style={{
                  color: rating.score === selected ? "#fff" : iconColor,
                  fontSize: 33,
                }}
              />
            </div>
          );
        })}
      </div>
      {showNoRating && NoRatingCheckbox}
    </>
  );
};

export default React.memo(SmileyFaceRating);

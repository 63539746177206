import { useState, useEffect } from "react";
import firebase from "../data/firebase";

const useGetImageMetadata = (imageRef: string) => {
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!imageRef) {
      return;
    }
    setLoading(true);
    const image = firebase.storage().refFromURL(imageRef);
    image.getMetadata()
      .then((metadata) => {
        setMetadata(metadata);
      }).catch((err) => {
        console.error("Unable to get image metadata", err);
      }).finally(() => {
        setLoading(false);
      });
  },[imageRef]);

  return [metadata, loading];
};

export default useGetImageMetadata;

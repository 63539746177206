import React from "react";
import DataRow from "./Helpers/DataRow";

const RadioRenderer = ({ element: { label }, value, valueOnly }) => {
  if (typeof value === "undefined") {
    return null;
  }
  return <DataRow label={label} value={value} valueOnly={valueOnly} />;
};

export default React.memo(RadioRenderer);

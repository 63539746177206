import React from "react";
import {
  changeHandler,
  setFieldValueWrapper,
} from "@flipbyte/formik-json/es/utils";
import I18n from "../../../../language/i18n";
import styles from "./Boolean.module.css";
import { classes } from "../../../../utils/dom";
import { BoniturConfigProps, BoniturFormProps, BoniturOptionsProps } from "../../BoniturTypes";

interface BooleanProps extends BoniturFormProps {
  config: BoniturConfigProps["config"] & {
    formGroupClass?: string;
  };
  options: BoniturOptionsProps["options"];
}

const OPTION = {
  no: false,
  yes: true,
};

const options = [
  { text: I18n.t("no"), optionValue: OPTION.no, dataName: "no" },
  { text: I18n.t("yes"), optionValue: OPTION.yes, dataName: "yes" },
];

const Boolean = ({ config, formik, value, error }: BooleanProps) => {
  const { name, label, formGroupClass } = config;
  const { setFieldValue } = formik;

  const isSelectedNo = value === OPTION.no;
  const isSelectedYes = value === OPTION.yes;

  // NOTE: for backward compatibility
  const hasFormGroupClass = formGroupClass === "fj-form-group-boolean";

  return (
    <fieldset className={hasFormGroupClass ? "" : styles.fieldset}>
      {!hasFormGroupClass && <h5 className={styles.legend}>{label}</h5>}
      <div>
        {options.map(({ text, optionValue, dataName }, index) => (
          <label
            key={`option-${index}`}
            className={styles.label} data-name={dataName}
            title={text}>
            <input
              type="radio"
              name={name}
              value={optionValue as any}
              className={styles.input}
              onClick={() => {
                changeHandler.bind(
                  this,
                  setFieldValueWrapper(setFieldValue, name),
                  formik,
                  config,
                )(optionValue);
              }
              }
              checked={isSelectedNo ? index === 0 : isSelectedYes ? index === 1 : false }
            />
            <p className={classes(
              styles.text,
              error && styles.textHasError,
            )}>{text}</p>
          </label>
        ))}
      </div>
    </fieldset>
  );
};

export default React.memo(Boolean);

import React, { useRef, useState, useMemo } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { find, get, debounce } from "lodash-es";
import Select from "react-select";
import { firestoreConnect } from "react-redux-firebase";
import Modal from "react-bootstrap-modal";

import { convertStringToRange } from "farmerjoe-common";
import { displaySettings } from "farmerjoe-common/lib/actions/actions";
import { editForm, openForm, openFormSchema } from "farmerjoe-common/lib/actions/form";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getCompanyGroupProfileForLoggedInUser, getFieldTableStateForCompany } from "farmerjoe-common/lib/selectors/user";
import { getFormSchemas, getFormSubmissions, getOpenFormId } from "farmerjoe-common/lib/selectors/forms";
import { getFormSchemasQuery, getFormSubmissionsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Forms";
import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { openField } from "farmerjoe-common/lib/actions/field";

import DateRangeFilter from "./DateRangeFilter";
import NoFieldBoniturForm from "./NoFieldBoniturForm";
import Table from "./Table/Table";
import Icon from "../Common/Icon";
import NavbarBasic from "../Common/NavbarBasic";
import NoResults from "../Common/NoResults";
import SearchInput from "../Common/SearchInput";
import { CreateNewButton } from "../Common/NewButton";
import withRouter from "../Router/withRouter";
import I18n from "../../language/i18n";
import type { FieldTableState } from "../../flowTypes";
import { saveFieldTableState } from "../../actions/fieldTable";
import fuseSearch from "../../utils/search";
import { markFormSubmissionAsRead } from "../../data/queries/formSubmissions";

import { TableListViewSwitch } from "../Common/TableListViewSwitch";
import { TableListViewState } from "../../flowTypes";
import Gallery from "./Gallery/Gallery";

import "./style.css";
import { FJAPPLEGREEN } from "../../styles/style";

type Props = {
  formSchemas?: any;
  formSubmissions?: any;
  loading?: boolean;
  actions?: Record<string, any>;
  openCompany?: string;
  history?: Record<string, any>;
  tableState?: FieldTableState;
  openFormId?: string | null;
  boniturSettings?: Record<string, any>;
};

const EmptyView = () => (
  // XXX: Translations
  <NoResults text={"Keine Bonituren in den Eingegeben Zeitraum."} />
);

const Bonituren = (props: Props) => {
  const {
    formSchemas,
    formSubmissions,
    loading,
    tableState,
    openFormId,
    boniturSettings,
    openCompany,
  } = props;

  const tableRef = useRef<Table>(null);

  const [search, setSearch] = useState("");
  const [showCreateNoFieldBonitur, setShowCreateNoFieldBonitur] = useState(false);
  const [currentViewState, setCurrentViewState] = useState(TableListViewState.Table);

  const schemaId = get(boniturSettings, "schema");

  const formSchema = schemaId
    ? find(formSchemas, { key: schemaId })
    : formSchemas[0];

  const fieldRequired = get(formSchema, "fieldRequired", true);

  const onDataRowClick = (submission) => {
    if (fieldRequired) {
      props.actions?.openField(submission.field_id);
    }
    markFormSubmissionAsRead(submission.key);
    props.actions?.openForm(submission.key);
    props.history?.push(
      `/company/${openCompany}/form/${submission.key}`,
    );
  };

  const onTableStateChange = (state) => {
    props.actions?.saveFieldTableState(openCompany, {
      ...tableState,
      "form-table": state,
    });
  };

  const selectBoniturOnChange = (formSchema) => {
    if (!formSchema) {
      return;
    }
    props.actions?.displaySettings(openCompany, "bonitur", {
      schema: formSchema.key,
    });
  };

  const selectBoniturGetOptionLabel = ({ name }) => `${name}`;
  const selectBoniturGetOptionValue = ({ key }) => key;

  const onExportClick = () => {
    tableRef.current?.exportData();
  };

  const submissions = useMemo(() => {
    return formSchema
      ? formSubmissions.filter(
        formSubmission => formSubmission.schema_id === formSchema.key,
      )
      : [];
  }, [formSchema, formSubmissions]);

  const filteredSubmissions = useMemo(() => {
    return search
      ? fuseSearch(submissions, search, {
        keys: [
          "meta.field_name",
          "meta.group_name",
          "meta.crop_name",
          "meta.crop_sort",
          ...Object.keys(submissions[0].formValues).map(key => `formValues.${key}`),
        ],
        distance: 3,
        threshold: 0.2,
      })
      : submissions;
  }, [submissions, search]);

  const onChange = debounce((word) => setSearch(word), 300);

  const tableViews = get(formSchema, "webViews", [TableListViewState.Table]);

  return (
    <div className="bonitur-table">
      <NavbarBasic
        title={I18n.t("bonitur.boniturs")}
        leftButton={

          <TableListViewSwitch
            currentState={currentViewState}
            onStateChange={(newViewState) => setCurrentViewState(newViewState)}
            availableStates={tableViews}
          />
        }
        rightButton={
          !fieldRequired
            ? (
              <CreateNewButton
                key="create-new-button"
                onClick={() => {
                  props.actions?.editForm(null);
                  props.actions?.openForm(null);
                  props.actions?.openFormSchema(formSchema.key);
                  setShowCreateNoFieldBonitur(true);
                }}
              />
            )
            : null 
        }
      />
      <div 
        className="filters"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridColumnGap: "10px",
          padding: "5px 10px",

        }}
      >
        <div className="toolbar-container-left">
          <DateRangeFilter />
          <Select
            className="bonitur-select"
            value={formSchema}
            options={formSchemas}
            onChange={selectBoniturOnChange}
            getOptionLabel={selectBoniturGetOptionLabel}
            getOptionValue={selectBoniturGetOptionValue}
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: "#ced4da",
                  boxShadow: "0 0 0 0px #ced4da",
                  "&:hover": {
                    borderColor: "#ced4da",
                  },
                };
              },
              option: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  backgroundColor: state.isSelected ? FJAPPLEGREEN : "white",
                };
              },
            }}
          />
        </div>
        <div style={{minWidth: "300px"}}>
          <SearchInput
            onChange={onChange}
            search={search}
          />
        </div>

        {currentViewState === TableListViewState.Table ? (
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
          }}>
            <button
              className="btn btn-secondary column-config-button"
              onClick={onExportClick}>
              <Icon iconType="fa" name="file-csv" style={{ fontSize: 20 }} />{" "}
              {I18n.t("export")}
            </button>
          </div>
        ) : null}
      </div>
      {currentViewState === TableListViewState.Table ? (
        <Table
          ref={tableRef}
          formSchema={formSchema}
          formSubmissions={filteredSubmissions}
          emptyView={<EmptyView />}
          tableState={tableState}
          onTableStateChange={onTableStateChange}
          onClick={onDataRowClick}
          openFormId={openFormId as any}
          loading={loading}
        />
      ) : (
        <Gallery 
          ref={tableRef}
          formSchema={formSchema}
          formSubmissions={filteredSubmissions}
          emptyView={<EmptyView />}
          onClick={onDataRowClick}
          loading={loading}
          openFormId={openFormId as any}
        />
      )}
      {showCreateNoFieldBonitur 
        ? ( 
          <Modal
            show={showCreateNoFieldBonitur}
            enforceFocus={false}
            backdrop="static"
          >
            <NoFieldBoniturForm
              onClose={() => {
                setShowCreateNoFieldBonitur(false);
              }}
            /> 
          </Modal>
        ) : null
      }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openForm,
          openFormSchema,
          editForm,
          saveFieldTableState,
          displaySettings,
          openField,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state) => {
  const openCompany = getOpenCompanyId(state);
  const formSchemas = getFormSchemas(state);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  const boniturSettings = get(state, [
    "filtersByCompany",
    openCompany,
    "displaySettings",
    "bonitur",
  ]);
  const schema = get(boniturSettings, "schema", "all");
  const date = get(boniturSettings, "date", "last:7");
  const range = convertStringToRange(date);
  const formSubmissions = getFormSubmissions(
    state,
    openCompany,
    browsingGroup,
    schema,
    range,
  );

  const loading = !hasLoaded(
    [
      getFormSubmissionsQuery(openCompany, browsingGroup, schema, range as any),
      getFormSchemasQuery(openCompany),
    ],
    state,
  );
  const tableState = getFieldTableStateForCompany(state, openCompany)[
    "form-table"
  ] || { columnIds: [], columnWidths: {}, sorting: [] };

  return {
    openCompany: openCompany,
    formSchemas: formSchemas,
    boniturSettings,
    browsingGroup,
    formSubmissions,
    myCompanyProfile: myCompanyProfile,
    loading,
    tableState,
    openFormId: getOpenFormId(state),
  };
};

export default compose<typeof Bonituren>(
  withRouter,
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const { openCompany, browsingGroup, boniturSettings } = props;

    const schema = get(boniturSettings, "schema", "all");
    const date = get(boniturSettings, "date", "last:7");
    const range = convertStringToRange(date);

    return [
      getFormSubmissionsQuery(openCompany, browsingGroup, schema, range as any),
      getFormSchemasQuery(openCompany),
    ];
  }),
)(Bonituren);

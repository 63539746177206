import React, { useState } from "react";

import { get } from "lodash-es";
import ImageLightbox from "react-image-lightbox";

import Icon from "../../Common/Icon";
import useGetImageMetadata from "../../../hooks/useImageMetadata";

import "./style.css";

const Image = ({ element: { label }, value, valueOnly }) => {
  const uri = get(value, "uri", null);
  const imageRef = get(value, "ref", null);
  const [visible, setVisible] = useState(false);
  const [metadata, loading] = useGetImageMetadata(imageRef);

  if (uri === null || loading) {
    return null;
  }

  const rotation = parseInt(get(metadata, "customMetadata.rotation", 0));

  return (
    <tr>
      <td colSpan={2}>
        <div style={{
          minWidth: "100%",
          minHeight: "200px",
          border: "1px solid #ddd",
          overflow: "hidden",
        }}>
          <div
            className={"bonitur-image-preview-comment fj-pointer"}
            style={{ backgroundImage: valueOnly ? "none" : `url(${uri})`, 
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              transform: `rotate(${rotation}deg)`,
            }}
            onClick={e => {
              setVisible(true);
              e.stopPropagation();
            }}>
            {valueOnly && (
              <Icon
                name={"image"}
                iconType={"fal"}
                style={{ fontSize: 28 }}
              />
            )}
            {visible && (
              <ImageLightbox
                enableZoom={false}
                mainSrc={uri}
                onCloseRequest={() => setVisible(false)}
                reactModalStyle={{ overlay: { zIndex: 10000 } }}
                onImageLoad={() => {
                  setTimeout(() => {
                    const imgElement = document.querySelector(".ril-image-current") as HTMLImageElement;
                    if (!imgElement) {
                      return;
                    }
                    const scaleFactor = Math.min(window.innerWidth / imgElement.offsetWidth, window.innerHeight / imgElement.offsetHeight);
                    const currentTransform = (imgElement as any).style.transform;
                    (imgElement as any).style.transform = `rotate(${rotation}deg) scale(${scaleFactor > 0.7 ? 0.7 : scaleFactor}) ${currentTransform}`;
                  }, 100);
                }}
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default React.memo(Image);

import React, { useMemo } from "react";
import "./style.css";

import { Loading } from "../../Loading/Loading";

import SmileyFaceRatingRenderer from "./Renderers/SmileyFaceRatingRenderer";
import TextRenderer from "./Renderers/TextRenderer";
import ImageRenderer from "./Renderers/ImageRenderer";

type Props = {
  onClick: (item: { key: string, field_id: string}) => void;
  loading: boolean;
  emptyView: React.ReactNode;
  formSchema: any;
  formSubmissions: any[];
  openFormId: string | null;
};

const Gallery = (props: Props) => {
  const { formSchema, formSubmissions, emptyView, onClick, loading } = props;
  const galleryItems = useMemo(() => getGalleryItems(formSubmissions, formSchema), [formSubmissions, formSchema]);

  if (loading) {
    return <Loading />;
  }

  const content = galleryItems.map((item) => {
    return(
      <div key={item.key} className={"gallery-item"} style={{
        backgroundColor: props.openFormId === item.key ? "#b3e2fe" : "white",
      }}
      >
        {item.values.map((value, idx) => {
          if (value.renderer === "fj-image") {
            const uri = value.value ? value.value.uri : null;
            const ref = value.value ? value.value.ref : null;
            return (
              <div key={idx} 
                style={{
                  cursor: "pointer",
                }}
                onClick={() => onClick(item)}
              >
                <ImageRenderer uri={uri} imageRef={ref} />
              </div>
            );
          }
          if (value.renderer === "fj-smiley-face-rating") {
            return (
              <div key={idx}>
                <SmileyFaceRatingRenderer label={value.label} value={value.value} />
              </div>
            );
          }
          return (
            <div key={idx}>
              <TextRenderer label={value.label} value={value.value} valueLabel={value.valueLabel} />
            </div>
          );
        })}
      </div>
    );
  });

  if (!content.length) {
    return emptyView;
  } 

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    }}>
      {content}
    </div>
  );
};

const getGalleryItems = (formSubmissions, formSchema) => {
  if (!formSchema) {
    return [];
  }

  if (!formSubmissions.length) {
    return [];
  }

  const schemaElements = formSchema.schema.elements;

  const galleryItemsBySchema = Object
    .keys(schemaElements)
    .map(key => schemaElements[key])
    .filter((element) => element.options.previewInGallery)
    .sort((a, b) => a.options.galleryPosition - b.options.galleryPosition || 9);

  return formSubmissions.map(submission => {
    return {
      key: submission.key,
      field_id: submission.field_id,
      values: galleryItemsBySchema.map((element) => {
        const v = submission.formValues[element.name];
        const { renderer, label, options: { valueLabel} } = element;
        return {
          renderer,
          label,
          value: v,
          valueLabel,
        };
      }),
    };
  });
};


export default Gallery as any;

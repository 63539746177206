import React from "react";
import styles from "./Text.module.css";

import { willRenderFormElement } from "../../utils";
import { BoniturConfigProps, BoniturFormProps, BoniturOptionsProps } from "../../BoniturTypes";
import { classes } from "../../../../utils/dom";

interface TextProps extends BoniturFormProps {
  config: BoniturConfigProps["config"] & {
    fieldType: "text";
    options: BoniturOptionsProps["options"];
  };
  value: string | number;
}

const Text = ({ config, formik, value, error }: TextProps) => {
  const {
    name,
    label,
    placeholder,
    fieldType,
    options = {},
    hasAutofocus,
    isDisabled,
  } = config;

  const { dependsOn } = options;

  const { handleChange, handleBlur, values } = formik;

  const willRender = willRenderFormElement(dependsOn, values);

  if (!willRender) {
    return null;
  }

  return (
    <div className={styles.container}>
      <label
        htmlFor={name}
        className={classes(
          styles.label,
          isDisabled && styles.isDisabled,
        )}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={fieldType}
        className={classes(
          styles.input,
          error && styles.hasError,
        )}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        autoFocus={hasAutofocus}
        disabled={isDisabled}
      />
    </div>
  );
};

export default React.memo(Text);

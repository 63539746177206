import React from "react";

const NonSupported = ({ element, value }) => {
  return (
    <tr>
      <td colSpan={2}>
        <div className={"text-danger"}>
          Renderer non-supported: {element.renderer}. Please make sure to use
          the latest farmerJoe version. If this message persist, please contact
          support.
        </div>
      </td>
    </tr>
  );
};

export default React.memo(NonSupported);

import React from "react";

import DataRow from "./Helpers/DataRow";
import { ContentRendererProps } from "./BoniturContentRenderersType";

import { getColorForValue, parseNumber } from "../utils";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import I18n from "../../../language/i18n";

const MathCalculationRenderer = ({
  element: {
    label,
    options: { valueLabel = "", colorSchemes = [], precision = 0 },
    name,
    className,
  },
  value,
  valueOnly,
}: ContentRendererProps ) => {
  const changedColor = getColorForValue(colorSchemes, { [name]: value });
  const style = { color: changedColor, fontWeight: "normal" };
  const numberFormatter = useNumberFormatter(I18n.locale, precision);

  const _value = parseNumber(value, numberFormatter);

  return (
    <DataRow
      className={className}
      label={label}
      value={_value}
      valueOnly={valueOnly}
      valueLabel={valueLabel}
      style={style}
    />
  );
};

export default React.memo(MathCalculationRenderer);

import React, { useState } from 'react';
import DatePicker from 'rc-calendar/lib/Picker';
import Calendar from 'rc-calendar';
import moment from 'moment';
import { get } from 'lodash-es';
import fire from 'firebase/app';

import { toDate } from 'farmerjoe-common';

import Dialog from '../../Dialog/Dialog';
import I18n, { getCurrentLocaleData } from '../../../language/i18n';
import * as constants from '../../../styles/style';
import { defaultDateFormat } from '../../../tcomb/utils/options';
import firebase from '../../../data/firebase';


const DateTimeForm = ({ show, onClose, comment }) => {
  const [newDate, setNewDate] = useState(toDate(comment.created));

  const onSave = () => {
    const commentRef = firebase.firestore().collection('comments').doc(comment.key);
    commentRef.update({ created: fire.firestore.Timestamp.fromDate(newDate) }).catch((error) => {
      console.error('Error updating document: ', error);
    });
    onClose();
  };

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={I18n.t('edit_date')}
      footer={
        <div className="d-flex flex-grow-1">
          <button className="btn btn-secondary ml-auto" onClick={onClose}>
            {I18n.t('cancel')}
          </button>{' '}
          <button className="btn btn-primary" onClick={onSave}>
            {I18n.t('save')}
          </button>
        </div>
      }
    >
      <div style={{ flex: 1 }}>
        <div
          style={{
            flex: 1,
          }}
        >
          <div className="modal-date">
            <div className={'mb-1'} key={0}>
              {I18n.t('select_date') + ':'}
            </div>
            <DatePicker
              placement={'bottom'}
              align={{ points: ['tc', 'bc'] }}
              calendar={
                <Calendar
                  showWeekNumber={false}
                  locale={get(getCurrentLocaleData(), 'calendar.default')}
                  showToday
                  showDateInput={false}
                  format={'YYYY-MM-DD'}
                  showOk={false}
                  disabledDate={(current) => moment(current).valueOf() > moment().valueOf()}
                />
              }
              defaultValue={moment(newDate).locale(I18n.locale)}
              onChange={date => setNewDate(date.toDate())}>
              {() => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 0,
                      paddingBottom: 0,
                      borderWidth: 1,
                      borderColor: constants.FJNEWGREEN,
                      borderRadius: 5,
                      backgroundColor: constants.FJNEWGREEN,
                      cursor: 'pointer',
                    }}>
                    <span
                      style={{ ...{ color: '#FFF', padding: 3, paddingTop: 6 } }}>
                      {defaultDateFormat(toDate(newDate))}
                    </span>
                  </div>
                );
              }}
            </DatePicker>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DateTimeForm;

import React from "react";
import Icon from "../../Common/Icon";

const SmileyRatingRenderer = ({
  element: { label },
  value,
  valueOnly,
}) => {
  if (value === undefined) {
    return null;
  }
  return (
    <tr>
      {!valueOnly && (
        <td>
          {label}
        </td>
      )}
      <td style={{ textAlign: "right" }}>
        <Icon
          name={value.smileyIcon}
          iconType={"fal"}
          style={{ color: value.color, fontSize: 28 }}
        />
      </td>
    </tr>
  );
};

export default React.memo(SmileyRatingRenderer);

import React, { useState } from "react";
import { FJWHITE } from "../../../styles/style";
import Icon from "../../Common/Icon";
import Text from "../../Common/Text";

const ColorPicker = ({ config, formik, value = false, error }) => {
  const colors = config.options.colors;
  const fieldName = config.name;
  const [selected, setSelected] = useState(value ? (value as any).score : null);
  const { setFieldValue } = formik;

  return (
    <div className={`fj-form-color-picker ${error ? " fj-form-color-picker-error" : ""}`}>

      {colors.map((color, index) => {
        return (
          <div
            key={`color-${index}`}
            style={{
              textAlign: "center",
            }}>
            <div
              key={color.label}
              className='fj-form-color-picker-item'
              style={getBackgroundColor(color)}
              onClick={() => {
                setFieldValue(fieldName, color);
                setSelected(color.label);
              }}
            >
              <Icon
                className='fj-form-color-picker-icon'
                name={"check"}
                iconType={"fal"}
                style={{
                  color: FJWHITE,
                  opacity: color.label === selected ? 1 : 0,
                  fontSize: 33,
                }}
              />
            </div>
            <Text>{color.label}</Text>
          </div>
        );
      })}

    </div>
  );
};

export function getBackgroundColor(color: any) {
  if (color.colorSecondary) {
    return { background: `linear-gradient(to bottom right, ${color.color} 50%, ${color.colorSecondary} 50%)` };
  }
  return { backgroundColor: color.color };
}

export default React.memo(ColorPicker);

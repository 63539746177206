import React from "react";

import { FJAPPLETEALBLUE } from "../../styles/style";

const BlueDot = () => {
  return (
    <div style={{
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: FJAPPLETEALBLUE,
    }}></div>
  );
};

export default BlueDot;

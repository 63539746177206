import React from "react";
import DataRow from "./Helpers/DataRow";
import { ContentRendererProps } from "./BoniturContentRenderersType";
import { getColorForValue } from "../utils";

const SliderRenderer = ({
  element: {
    label,
    options: { colorSchemes = [], valueLabel = "" },
    name,
  },
  value,
  valueOnly,
}: ContentRendererProps) => {
  const changedColor = getColorForValue(colorSchemes, { [name]: value });
  const style = { color: changedColor };
  return <DataRow label={label} value={value} valueOnly={valueOnly} valueLabel={valueLabel} style={style}/>;
};

export default React.memo(SliderRenderer);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { editField } from 'farmerjoe-common/lib/actions/field';
import {
  deleteComment,
  editComment,
} from 'farmerjoe-common/lib/actions/comment';
import { editWaitTime } from 'farmerjoe-common/lib/actions/waitTime';
import { editFertilizing } from 'farmerjoe-common/lib/actions/actions';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { openAnalysis } from 'farmerjoe-common/lib/actions/analysis';
import { editForm } from 'farmerjoe-common/lib/actions/form';

const selector = (state) => {
  const openCompany = selectors.getOpenCompanyId(state);

  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  return {
    myCompanyProfile,
    openCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          editComment,
          deleteComment,
          editField,
          editWaitTime,
          editFertilizing,
          openAnalysis,
          editForm,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(selector, mapDispatchToProps);

import React from "react";

const TextRenderer = (props) => {
  const { label, value, valueLabel } = props;
  return (
    <div style={{
      display: "grid",
      gridTemplateColumns: "1fr auto",
    }}>
      <div>{label}</div>
      <div style={{textAlign: "left"}}>{value} {valueLabel}</div>
    </div>
  );
};

export default TextRenderer;

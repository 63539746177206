import React from "react";
import {
  changeHandler,
  setFieldValueWrapper,
} from "@flipbyte/formik-json/es/utils";
import ImageComponent from "./Image/ImageComponent";
import "./style.css";
import { willRenderFormElement } from "../utils";
import Label from "../Fields/Label";

const Image = ({ config, formik, value, error }) => {
  const { name, label, labelClass, options = {}, fieldType } = config;
  const { dependsOn } = options;

  const { setFieldValue, values } = formik;

  const willRender = willRenderFormElement(dependsOn, values);
  if (!willRender) {
    return null;
  }

  const updateValue = changeHandler.bind(
    this,
    setFieldValueWrapper(setFieldValue, name),
    formik,
    config,
  );

  const imageWithoutLabel = ImageRenderWithoutLabel(updateValue, value, error);
  return fieldType === "fj-conditional-field"
    ? ImageRenderWithLabel(name, label, labelClass, imageWithoutLabel)
    : imageWithoutLabel;
};

const ImageRenderWithLabel = (name, label, labelClass, children) => {
  return (
    <div>
      <Label htmlFor={name} className={labelClass}>
        {label}
      </Label>
      {children}
    </div>
  );
};

const ImageRenderWithoutLabel = (updateValue, value, error) => {
  return (
    <div>
      <ImageComponent
        onUploaded={(data) => {
          updateValue(data);
        }}
        onUploading={() => {
          updateValue({ loading: true });
        }}
        onDelete={() => {
          updateValue(null);
        }}
        value={value}
        error={error}
      />
    </div>
  );
};

export default React.memo(Image);

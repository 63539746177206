import React, { useState, useEffect } from "react";
import ImageLightbox from "react-image-lightbox";
import Icon from "../../../Common/Icon";
import firebase from "../../../../data/firebase";
import useGetImageMetadata from "../../../../hooks/useImageMetadata";


const ImageRenderer = (props) => {
  const { uri, imageRef } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [metadata, loading] = useGetImageMetadata(imageRef);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [updated, setUpdated] = useState(false);
  const [enlarged, setEnlarged] = useState(false);

  const transform = getTransform(updated, rotation, scale, metadata);

  useEffect(() => {
    return () => {
      setUpdated(false);
    };
  }, []);

  useEffect(() => {
    const { customMetadata } = metadata as any;
    if (customMetadata) {
      setRotation(parseInt(customMetadata.rotation));
      setScale(parseFloat(customMetadata.scale));
    }
  }, [metadata]);

  useEffect(() => {
    setImageMetadata(imageRef, { rotation, scale } );
  }, [imageRef, rotation, scale]);

  if (loading) {
    return null;
  }

  // Handlers
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className={"gallery-image-container"}
      style={{
        position: "relative",
        overflow: "hidden",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{
        backgroundImage: `url(${uri})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        transform: `${transform}`,
        transition: "transform 0.5s",
        transformOrigin: "center",
        height: "100%",
      }} />
      { isHovered &&
        <div style={{
          position: "absolute",
          bottom: "0px",
          right: "1px",
          display: "flex",
          flexDirection: "row",
          opacity: 0.8,
        }}>
          <EnlargeImage
            onClick={(e) => {
              e.stopPropagation();
              setEnlarged(true);
            }}
          />
          <ImageMagnifier
            onClick={(e) => {
              e.stopPropagation();
              setScale(scale => scale - 0.5 < 0 ? 0.1 : scale - 0.1);
              setUpdated(true);
            }}
            direction={"out"}
          />
          <ImageMagnifier
            onClick={(e) => {
              e.stopPropagation();
              setScale(scale => scale + 0.1);
              setUpdated(true);
            }} 
            direction={"in"}/>
          <RotateImageArrow onClick={(e) => {
            e.stopPropagation();
            setRotation((rotation + 90) % 360);
            setUpdated(true);
          }} />
        </div>
      }
      {enlarged &&
        <ImageLightbox
          mainSrc={uri}
          onCloseRequest={() => setEnlarged(false)}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onImageLoad={() => {
            setTimeout(() => {
              const imgElement = document.querySelector(".ril-image-current") as HTMLImageElement;
              if (!imgElement) {
                return;
              }
              const scaleFactor = Math.min(window.innerWidth / imgElement.offsetWidth, window.innerHeight / imgElement.offsetHeight);
              const currentTransform = (imgElement as any).style.transform;
              (imgElement as any).style.transform = `rotate(${rotation}deg) scale(${scaleFactor > 0.7 ? 0.7 : scaleFactor}) ${currentTransform}`;
            }, 100);
          }}
          toolbarButtons={[]}
          enableZoom={false}
        />
      }
    </div>
  );
};

export default React.memo(ImageRenderer);

const RotateImageArrow = (props: { onClick: (...args: any) => any}) => {
  return (
    <div
      style={{margin: "0px 2px"}}
      onClick={props.onClick}>
      <Icon name={"redo"} iconType={"fa"} style={{ fontSize: 20, color: "white" }} />
    </div>
  );
};


const ImageMagnifier = (props: { onClick: (...args: any) => any, direction: "in" | "out"}) => {
  const iconName = props.direction === "in" ? "plus" : "minus";
  return (
    <div
      style={{margin: "0px 2px"}}
      onClick={props.onClick}
    >
      <Icon name={iconName} iconType={"fa"} style={{ fontSize: 20, color: "white" }} />
    </div>
  );
};

const EnlargeImage = (props: { onClick: (...args: any) => any}) => {
  return (
    <div
      style={{margin: "0px 2px"}}
      onClick={props.onClick}
    >
      <Icon name={"expand"} iconType={"fa"} style={{ fontSize: 20, color: "white" }} />
    </div>
  );
};

const setImageMetadata = (imageRef, {
  rotation,
  scale,
}: { rotation: number, scale: number}) => {
  if (!imageRef) {
    return null;
  }

  const image = firebase.storage().refFromURL(imageRef);
  return image.updateMetadata({
    customMetadata: {
      rotation: rotation.toString(),
      scale: scale.toString(),
    },
  }).catch((err) => {
    console.error("Unable to set image metadata", err);
  });
};

const getTransform = (updated, rotation, scale, metadata) => {
  const { customMetadata = {} } = metadata;

  const finalRotation = updated
    ? parseInt(customMetadata.rotation) !== rotation
      ? rotation
      : parseInt(customMetadata.rotation)
    : customMetadata.rotation ?? rotation;

  const finalScale = updated
    ? parseFloat(customMetadata.scale) !== scale
      ? scale
      : parseFloat(customMetadata.scale)
    : customMetadata.scale ?? scale;

  return `rotate(${finalRotation}deg) scale(${finalScale})`;
};

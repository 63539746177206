import React from "react";
import "./style.css";
import DataRow from "./Helpers/DataRow";

type LabelRendererProps = {
  element: {
    label: string;
    labelClass?: string;
  };
};

const LabelRenderer = ({element: { label, labelClass }}: LabelRendererProps ) => {
  return <DataRow label={label} value={null} valueOnly={false} className={labelClass}/>;
};
export default React.memo(LabelRenderer);

import Calendar, { Props as CalendarProps } from "rc-calendar";
import I18n from "../../language/i18n";
import moment from "moment";
import { isFunction } from "lodash-es";

/**
 * rc-calendar relies on setting the locale in moment for translating months, days etc.,
 * but if no value prop is given to calendar it uses the default which is english.
 * This component fixes it by using the current locale for new moment objects.
 */

export function getTodayTime(value) {
  const today = moment();
  today.locale(I18n.locale).utcOffset(value.utcOffset());
  return today;
}

function getNow() {
  return moment().locale(I18n.locale);
}

function getNowByCurrentStateValue(value) {
  let ret: any = void 0;
  if (value) {
    ret = getTodayTime(value);
  } else {
    ret = getNow();
  }
  return ret;
}

export default class FixedCalendar extends Calendar {
  constructor(props: CalendarProps, context?: any) {
    super(props, context);
    (this.state as any).value = getNow();
  }

  static getDerivedStateFromProps(nextProps, state) {
    const value = nextProps.value;
    let selectedValue = nextProps.selectedValue;

    let newState: any = {};

    if ("mode" in nextProps && state.mode !== nextProps.mode) {
      newState = { mode: nextProps.mode };
    }
    if ("value" in nextProps) {
      newState.value =
        value ||
        nextProps.defaultValue ||
        getNowByCurrentStateValue(state.value);
    }
    if ("selectedValue" in nextProps) {
      if (selectedValue && isFunction(selectedValue.locale)) {
        selectedValue = selectedValue.locale(I18n.locale);
      }
      newState.selectedValue = selectedValue;
    }

    return newState;
  }
}

import React, { useState } from "react";
import { willRenderFormElement } from "../../utils";
import styles from "./TextArea.module.css";
import { classes } from "../../../../utils/dom";
import { BoniturConfigProps, BoniturFormProps, BoniturOptionsProps } from "../../BoniturTypes";

interface TextAreaProps extends BoniturFormProps {
  config: BoniturConfigProps["config"] & {
    options: BoniturOptionsProps["options"];
  };
  value: string;
};

const MAX_CHARACTER_LENGTH = 300;

const TextArea = ({ config, formik, value, error }: TextAreaProps) => {
  const {
    name,
    label,
    options,
    placeholder = "Notiz...",
    hasAutofocus,
    isDisabled,
  } = config;

  const [count, setCount] = useState(0);

  const { dependsOn, maxLength = MAX_CHARACTER_LENGTH } = options;

  const { handleChange, handleBlur, values } = formik;

  const willRender = willRenderFormElement(dependsOn, values);
  if (!willRender) {
    return null;
  }

  return (
    <div className={styles.container}>
      <label htmlFor={name}
        className={classes(
          styles.label,
          isDisabled && styles.isDisabled,
        )}>
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        maxLength={maxLength}
        className={classes(
          styles.textarea,
          error && styles.hasError,
        )}
        value={value}
        onChange={e => {
          setCount(e.target.value.length);
          handleChange(e);
        }}
        onBlur={handleBlur}
        rows={5}
        placeholder={placeholder}
        autoFocus={hasAutofocus}
        disabled={isDisabled}
      />
      <small className={
        classes(
          styles.counterText,
          isDisabled && styles.isDisabled,
        )}>
        {`${count} / ${maxLength}`}
      </small>
    </div>
  );
};
export default React.memo(TextArea);

import React from "react";
import { getColorForValue, willRenderFormElement } from "../../utils";
import styles from "./Amount.module.css";
import { classes } from "../../../../utils/dom";
import {
  BoniturConfigProps,
  BoniturFormProps,
  BoniturOptionsProps,
} from "../../BoniturTypes";
import { calculate } from "farmerjoe-common/lib/utils/math";
import Separator from "../../../Common/Separator";

interface AmountProps extends BoniturFormProps {
  config: BoniturConfigProps["config"] & {
    fieldType: "text";
    labelClass?: string;
    description?: string;
    // NOTE: for backward compatibility
    inputUnit?: string;
    calculatePercentageOf?: string;
    options: BoniturOptionsProps["options"] & {
      valueLabel?: string;
    };
  };
  value: string | number;
}

const Amount = ({ config, formik, value, error }: AmountProps) => {
  const {
    name,
    label,
    description,
    labelClass,
    placeholder,
    fieldType,
    options,
    hasAutofocus,
    isDisabled,
    inputUnit,
    calculatePercentageOf,
  } = config;

  const { dependsOn, valueLabel, withSeparator, colorSchemes = [] } = options;
  const { handleBlur, handleChange, values } = formik;

  const result =
    calculate(
      {
        evaluation: `round((${name} * 100) / ${calculatePercentageOf})`,
      },
      values,
    ) || "0";

  const color = getColorForValue(colorSchemes, { [name]: result });
  const willRender = willRenderFormElement(dependsOn, values);

  if (!willRender) {
    return null;
  }

  const suffix = valueLabel || inputUnit;
  const hasLabelColon = labelClass === "label--colon";

  return (
    <>
      <div className={styles.container}>
        <div>
          <label
            htmlFor={name}
            className={classes(styles.label, isDisabled && styles.isDisabled)}
          >
            {`${label}${hasLabelColon ? ":" : ""}`}
          </label>
          {description ? (
            <label className={classes(styles.description)}>{description}</label>
          ) : null}
        </div>
        <div className={styles.inputWrapper}>
          {calculatePercentageOf && <span style={{ color }}>{result}%</span>}
          <div className={styles.containerInput}>
            <input
              id={name}
              name={name}
              className={classes(
                styles.input,
                suffix && styles.hasSuffix,
                error && styles.hasError,
              )}
              value={value}
              onChange={(e) => {
                e.target.value = e.target.value.replace(",", ".");
                handleChange(e);
              }}
              onBlur={handleBlur}
              placeholder={placeholder || ""}
              autoFocus={hasAutofocus}
              disabled={isDisabled}
              type={fieldType}
            />
            <div className={styles.containerSuffix}>
              {suffix && <small className={styles.suffix}>{suffix}</small>}
            </div>
          </div>
        </div>
      </div>
      {withSeparator ? <Separator style={{ marginTop: 15 }} /> : null}
    </>
  );
};

export default React.memo(Amount);

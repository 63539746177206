/**
 * Add a property to firebase object (normally the missing id)
 *
 * @param keyValue
 * @param object
 * @param keyName
 * @returns {{}}
 */
export function addKeyToObj(keyValue, object, keyName = "key") {
  return {
    ...object,
    [keyName]: keyValue,
  };
}

export function getUserPath(userId) {
  if (!userId) {
    throw new Error("No user id provided.");
  }

  return `/users/${userId}`;
}

import React from "react";
import I18n from "../../../language/i18n";
import { toDate } from "farmerjoe-common";
import moment from "moment";

type BoniturAgeRendererProps = {
  createdDate: Date;
};

const BoniturAgeRenderer = ({ createdDate }: BoniturAgeRendererProps) => {
  if (typeof createdDate === "undefined") {
    return null;
  }
  const today = moment().startOf("day");
  const createdStartDate = moment(toDate(createdDate)).startOf("day");

  return (
    <div style={{ fontWeight: "normal" }}>
      {I18n.t("x_days", { days: today.diff(createdStartDate, "days") })}
    </div>
  );
};

export default React.memo(BoniturAgeRenderer);

import React from 'react';

import { getColor } from 'farmerjoe-common/lib/utils/Colors';

import Comment from '../Comments/Comment';
import FlatList from '../Common/FlatList';
import NoResults from '../Common/NoResults';
import { Loading } from '../Loading/Loading';
import type { Comment as CommentType } from '../../flowTypes';
import I18n from '../../language/i18n';

import './style.css';

type Props = {
  items?: CommentType[];
  loadingMore?: boolean;
  onEndReached?: (...args: Array<any>) => any;
  onCommentPress?: (...args: Array<any>) => any;
  showCropVariety?: boolean;
};

export default class LatestActivitiesList extends React.PureComponent<Props> {
  render() {
    const { items, loadingMore, onEndReached } = this.props;

    return (
      <div className="latest-activities-list-container">
        {items?.length
          ? (
            <FlatList
              className="latest-activities-list"
              data={items}
              onEndReached={() => {
                if (onEndReached) onEndReached();
              }}
              endReachedThreshold={0.8}
              ListFooterComponent={() => {
                return (
                  <div style={{ height: 20, marginBottom: 20 }}>
                    {loadingMore
                      ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}>
                          <Loading scale={1.0} size={'small'} />
                        </div>
                      )
                      : null}
                  </div>
                );
              }}
              renderItem={({ item }) => this.renderCommentRow(item)}
            />
          )
          : (
            <NoResults text={I18n.t('latestActivities.noActivity')} />
          )}
      </div>
    );
  }

  renderCommentRow(item: CommentType) {
    const { fieldMeta, cropMeta } = item;
    const { onCommentPress, showCropVariety } = this.props;
    return (
      <div
        key={item.key}
        style={{
          marginTop: 10,
          backgroundColor: 'rgb(241, 240, 245)',
          borderRadius: 5,
        }}
        onClick={() => {
          if (onCommentPress) onCommentPress(item);
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <div style={{}}>
            {cropMeta
              ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: getColor(cropMeta.color),
                    borderRadius: 5,
                    padding: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}>
                  <span style={{ color: '#000' }}>{cropMeta.name}&nbsp;</span>
                  {cropMeta.art && showCropVariety
                    ? (
                      <span>
                        {' - '}
                        {cropMeta.art}
                      </span>
                    )
                    : null}
                </div>
              )
              : null}

            {fieldMeta
              ? (
                <span style={{ marginLeft: 5 }}>{fieldMeta.name}</span>
              )
              : null}
          </div>
        </div>
        <Comment comment={item} displayMenu={false} onClick={() => {}} />
      </div>
    );
  }
}

import React from "react";
import { element } from "prop-types";
import Text from "../../Common/Text";
import { getBackgroundColor } from "../Renderers/ColorPicker";

const ColorPickerRenderer = ({
  element: { label },
  value,
  valueOnly,
}) => {
  if (valueOnly) {
    return getTableLayout(value);
  }
  return getFormLayout(label, value);
};

function getFormLayout(label, value) {
  return (
    <tr>
      <td>
        {label}
      </td>
      <td style={{ textAlign: "right" }}>
        <Text
          style={{
            display: "inline-flex",
          }}
        >{value.label}</Text>
        <div
          key={value.label}
          className='fj-color-picker-renderer fj-color-picker-renderer-form'
          style={getBackgroundColor(value)}
        />
      </td>
    </tr>
  );
}

function getTableLayout(value) {
  return (
    <tr>
      <td style={{ textAlign: "right" }}>
        <div
          key={value.label}
          className='fj-color-picker-renderer fj-color-picker-renderer-table'
          style={getBackgroundColor(value)}
        />
        <Text
          style={{
            display: "inline-flex",
            color: value.label === "Problem" ? value.colorSecondary : null,
          }}
        >{value.label}</Text>
      </td>
    </tr>
  );
}

export default React.memo(ColorPickerRenderer);

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import * as commentActions from 'farmerjoe-common/lib/actions/comment';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { UserObj } from 'farmerjoe-common/lib/utils/User';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getComment } from 'farmerjoe-common/lib/selectors/comments';
import { getCommentQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Comments';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';

import Icon from '../Common/Icon';
import TextInput from '../Common/TextInput';
import { AlertConfirmDialog } from '../Dialog/Dialog';

import * as constants from '../../styles/style';
import I18n from '../../language/i18n';
import { KeyCodes } from '../../flowTypes';
import { dummy } from '../../utils/Comment';

const DIALOG_CONFIRM_VALUE = 'ok';

// TODO: improve typings
type Props = any;
type State = any;

class CommentForm extends PureComponent<Props, State> {
  private commentInput?: TextInput;

  constructor(props) {
    super(props);

    this.state = {
      comment: this.props.commentToEdit ? this.props.commentToEdit.text : '',
      focused: false,
      behavior: 'padding',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.commentToEdit &&
      this.props.commentToEdit !== prevProps.commentToEdit
    ) {
      this.setState({
        ...this.state,
        comment: this.props.commentToEdit.text,
      });

      this.commentInput?.focus();
      this.onFocus();
      this.props.onChange(this.props.commentToEdit.text);
    }

    // user canceled edit
    if (
      typeof this.props.commentToEdit === 'undefined' &&
      this.props.commentToEdit
    ) {
      this.clearCommentForm();
    }
  }

  addComment() {
    if (this.props.commentToEdit) {
      this.edit();
      return;
    }

    this.save();
  }

  componentWillUnmount() {
    if (this.props.commentToEdit) {
      this.props.actions.editComment(null);
    }
  }

  edit() {
    const { auth, profile, firebase, commentToEdit } = this.props;
    const userAuth = {
      ...auth,
      ...profile,
    };

    if (this.state.comment === '') {
      this.setState({
        confirm: {
          title: I18n.t('comment.delete'),
          children: I18n.t('comment.doYouReallyWantToDelete'),
          buttons: [
            { label: I18n.t('cancel'), value: 'cancel' },
            { label: I18n.t('yes'), value: DIALOG_CONFIRM_VALUE, className: 'btn-danger' },
          ],
          onClose: result => {
            if (result === DIALOG_CONFIRM_VALUE) {
              this.delete();
            }
            this.setState({ confirm: null });
          },
        },
      });
      return;
    }

    const updatedComment = {
      ...commentToEdit,
      modified_by: UserObj(userAuth),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      text: this.state.comment,
    };

    const db = firebase.firestore();
    const commentRef = db.collection('comments').doc(updatedComment.key);

    const batch = db.batch();

    batch.update(commentRef, updatedComment);

    batch.commit();

    this.clearCommentForm();
    this.props.actions.editComment(null);
  }

  delete() {
    const { commentToEdit, firebase } = this.props;

    const db = firebase.firestore();
    const commentRef = db.collection('comments').doc(commentToEdit.key);

    commentRef
      .delete()
      .then(() => { })
      .catch(error => {
        console.log(error);
      });

    this.clearCommentForm();
    this.props.actions.editComment(null);
  }

  save() {
    const { field, auth, profile, firebase } = this.props;
    const userAuth = {
      ...auth,
      ...profile,
    };

    // Empty comments are not allowed
    if (this.state.comment === '') {
      return;
    }

    const db = firebase.firestore();
    const commentRef = db.collection('comments').doc();

    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        field.activeCrop,
        userAuth,
        'user.comment',
      ),
      text: this.state.comment,
    };

    const batch = db.batch();

    batch.set(commentRef, comment);

    batch.commit();

    this.clearCommentForm();
  }

  clearCommentForm() {
    this.setState({
      comment: '',
    });

    this.commentInput?.blur();

    this.props.onChange('');
  }

  onFocus() {
    this.setState({ focused: true });

    this.props.onFocus();
  }

  onBlur() {
    this.setState({ focused: false });

    this.props.onBlur();
  }

  render() {
    return (
      <div className="comment-form d-flex flex-column" style={{ flex: 1 }}>
        {this.props.commentToEdit
          ? (
            <div
              className={'d-flex flex-row justify-content-end'}
              style={{ marginRight: 60 }}>
              <div
                onClick={() => {
                  this.props.actions.editComment(null);
                  this.clearCommentForm();
                }}>
                <span
                  className={'fj-small text-right'}
                  style={{
                    color: constants.FJNAVCOLOR,
                    paddingTop: 5,
                    paddingBottom: 5,
                    cursor: 'default',
                  }}>
                  {I18n.t('cancel')}
                </span>
              </div>
            </div>
          )
          : null}

        <div
          className={'d-flex flex-row justify-content-end align-items-center'}>
          <TextInput
            ref={component => (this.commentInput = component as any)}
            style={{ width: '100%' }}
            onFocus={() => this.onFocus()}
            onBlur={() => this.onBlur()}
            onChange={e => {
              const comment = e.target.value;
              this.setState({ comment });
              this.props.onChange(comment);
            }}
            onKeyUp={e => {
              if (e.ctrlKey && e.keyCode === KeyCodes.Enter) {
                this.addComment();
              }
            }}
            value={this.state.comment}
            multiline={true}
            rows={Math.max(
              1,
              Math.min(5, this.state.comment.split('\n').length),
            )}
            placeholder={I18n.t('enter_notes_here')}
          />

          {this.renderSubmitButton()}
        </div>
        <AlertConfirmDialog
          {...this.state.confirm}
          show={!!this.state.confirm}
        />
      </div>
    );
  }

  renderSubmitButton() {
    if (
      this.state.comment.length ||
      (!this.state.comment.length && this.props.commentToEdit)
    ) {
      return (
        <div
          className={'justify-content-center align-items-center'}
          onClick={() => this.addComment()}>
          <Icon
            name="send"
            iconType={'fj'}
            style={{
              fontSize: 25,
              marginTop: 3,
              marginLeft: 20,
              marginRight: 14,
              marginBottom: 2,
              color: constants.FJNAVCOLOR,
            }}
          />
        </div>
      );
    }

    return null;
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  const company = getCompany(state.firestore.data, openCompany);
  const browsingGroupKey = getBrowsingGroupKey(state, openCompany);
  const field = ownProps.field;

  const editCommentId = selectors.getEditCommentId(state);

  const commentToEdit = editCommentId
    ? getComment(state.firestore.data, editCommentId)
    : undefined;


  return {
    company,
    field,
    browsingGroupKey,
    commentToEdit,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    editCommentId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...commentActions,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedCommentForm = firestoreConnect((props) => {
  if (!props.editCommentId) {
    return [];
  }
  return [
    getCommentQuery(props.editCommentId),
  ];
})(CommentForm);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedCommentForm);

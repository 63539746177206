import React from "react";
import DatePicker from "rc-calendar/lib/Picker";
import moment from "moment";
import { get } from "lodash-es";

import { toDate } from "farmerjoe-common";

import I18n, { getCurrentLocaleData } from "../../language/i18n";
import FixedCalendar from "../utils/FixedCalendar";

import * as constants from "../../styles/style";
import "./style.css";

type Props = {
  locals?: any;

};

/**
 * TODO: this is not nearly the same as ModalDateFactory
 *
 * the problem is that for some reason if we were to treat this as a template the default value
 * for date is set to today and we can't ask the user to explicitly set a date
 */
export class ModalDateComponent extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  private disabledDate(current) {
    const { locals } = this.props;
    const currentDateValue = current && current.valueOf();
    if (
      (locals.minimumDate &&
        currentDateValue <
        moment(locals.minimumDate).valueOf()) ||
      (locals.config.minimumDate &&
        currentDateValue <
        moment(locals.config.minimumDate).valueOf()
      )
    ) {
      return true;
    }

    if (
      (locals.maximumDate &&
       currentDateValue >
        moment(locals.maximumDate).valueOf()) ||
      (locals.config.maximumDate &&
       currentDateValue >
        moment(locals.config.maximumDate).valueOf()
      )
    ) {
      return true;
    }
    return false;
  }

  render() {
    const locals = this.props.locals;

    let formattedValue = String(locals.value);

    let disabled = false;

    if (locals.config) {
      if (locals.config.format) {
        formattedValue = locals.config.format(toDate(locals.value));
      }

      if (locals.config.disabled) {
        disabled = true;
      }
    }

    const label = locals.label ? <span>{locals.label}</span> : null;

    let container: any = null;

    if (!formattedValue) {
      formattedValue = I18n.t("datePleaseSelect");
    }

    if (disabled) {
      return (
        <div style={{ marginBottom: 25 }}>
          {label}
          <span style={{ ...constants.styles.stdSize, ...{ marginTop: 0 } }}>
            {formattedValue}
          </span>
        </div>
      );
    }

    container = (
      <div className="modal-date">
        <div className={"mb-1"} key={0}>
          {label}
        </div>
        <DatePicker
          placement={"bottom"}
          align={{ points: ["tc", "bc"] }}
          calendar={
            <FixedCalendar
              showWeekNumber={false}
              locale={get(getCurrentLocaleData(), "calendar.default")}
              showToday
              showDateInput={false}
              format={"YYYY-MM-DD"}
              showOk={false}
              disabledDate={this.disabledDate.bind(this)}
            />
          }
          defaultValue={
            locals.value != null
              ? moment(toDate(locals.value)).locale(I18n.locale)
              : null
          }
          onChange={date => locals.onChange(date.toDate())}>
          {({ value }) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0,
                  paddingBottom: 0,
                  borderWidth: 1,
                  borderColor: locals.hasError
                    ? constants.FJAPPLERED
                    : constants.FJNEWGREEN,
                  borderRadius: 5,
                  backgroundColor: locals.hasError
                    ? constants.FJAPPLERED
                    : constants.FJNEWGREEN,
                  cursor: "pointer",
                  // backgroundColor: '#EEE'
                }}>
                <span
                  style={{ ...{ color: "#FFF", padding: 3, paddingTop: 6 } }}>
                  {formattedValue}
                </span>
              </div>
            );
          }}
        </DatePicker>
      </div>
    );

    return container;
  }
}

export default function modaldate(locals) {
  return (
    <div>
      <div>
        <ModalDateComponent locals={locals} />
      </div>
    </div>
  );
}

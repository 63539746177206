import copyToClipboard from 'clipboard-copy';
import { getCropRatingPdf } from '../../../data/api/crop-ratigs';
import * as CommentUtils from '../../../utils/Comment';

import I18n from '../../../language/i18n';

// XXX: Not used
const analysisHandler = (comment, actions, history, openCompany) => {
  actions.openAnalysis(comment.text.key);
  history.push(
    `/company/${openCompany}/analysis/${comment.text.key}`,
  );
};

export const copyHandler = (comment) => {
  copyToClipboard(CommentUtils.formatCommentTextForSharing(comment));
};

export const editHandler = (comment, actions, cb) => {
  if (!CommentUtils.isSystem(comment.type)) {
    actions.editComment(comment.key);
    return;
  }

  if (CommentUtils.typeIs('crop.yield', comment.type)) {
    cb.setComment(comment);
    cb.showYieldForm(true);
    return;
  }

  if (
    CommentUtils.typeIs('field', comment.type) ||
    CommentUtils.typeIs('crop', comment.type)
  ) {
    actions.editField(comment.field_id);
    cb.showCreateForm(true);
    return;
  }

  if (CommentUtils.typeIs('wait_time', comment.type)) {
    actions.editWaitTime({
      wait_time_id: comment.foreign_id || CommentUtils.getUid(comment.type),
      company_id: comment.company_id,
      field_id: comment.field_id,
      crop_id: comment.active_crop_uid,
    });

    cb.setComment(comment);
    cb.showWaitTimeForm(true);
    return;
  }

  if (CommentUtils.typeIs('fertilizing', comment.type)) {
    actions.editFertilizing(comment.text.key);
    cb.setComment(comment);
    cb.showFertilizingForm(true);
    return;
  }

  if (CommentUtils.typeIs('bonitur', comment.type)) {
    actions.editForm(comment.foreign_id);
    cb.setComment(comment);
    cb.showBoniturForm(true);
  }
};

export const deleteHandler = (comment, cb) => {
  const { title, message } = {
    wait_time: { title: I18n.t('waittime.delete'), message: I18n.t('waittime.doYouReallyWantToDelete') },
    fertilizing: { title: I18n.t('fertilizer.delete'), message: I18n.t('fertilizer.doYouReallyWantToDelete') },
    bonitur: { title: 'Bonitur löschen', message: 'Wollen Sie wirklich die Bonitur löschen?' },
  }[comment.type] || {
    title: I18n.t('comment.delete'),
    message: I18n.t('comment.doYouReallyWantToDelete'),
  };

  cb(title, message);
};

export const printHandler = (comment) => {
  getCropRatingPdf(comment.foreign_id);
};

export const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.59&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
export const BASE_API_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL;
export const REQUEST_EMAIL_ACTIVATION_URL = `${BASE_API_URL}/requestEmailActivation`;
export const REQUEST_REGISTRATION_URL = `${BASE_API_URL}/requestRegistration`;
export const REQUEST_UPDATE_PROFILE_URL = `${BASE_API_URL}/updateProfile`;
export const REQUEST_PASSWORD_RESET = `${BASE_API_URL}/requestPasswordReset`;
export const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const REQUEST_ACCOUNT_REMOVAL = `${BASE_API_URL}/requestAccountRemoval`;
export const FJ_SUPPORT_USER_EMAIL = "support@farmerjoe.com";

export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID || "";
export const ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY || "";
export const ALGOLIA_INDEX_USERS = process.env.REACT_APP_ALGOLIA_INDEX_USERS || "";
export const ALGOLIA_INDEX_COMPANIES = process.env.REACT_APP_ALGOLIA_INDEX_COMPANIES || "";

export const STRIPE_CLIENT_PK = process.env.REACT_APP_STRIPE_CLIENT_PK || "";

import React from "react";
import { Polygon, DrawingManager, Marker, Polyline } from "@react-google-maps/api";
import { findIndex, without } from "lodash-es";

import MapControl, {
  ControlButton,
  ControlContainer,
  LEFT_TOP,
  RIGHT_BOTTOM,
  TOP_LEFT,
} from "./MapControl";
import { fieldPolygonOptions, measurePolylineOptions } from "./Map";
import MapGeoSearch from "./MapGeoSearch";
import PinPositionMarker from "./PinPositionMarker";
import DistanceMarker from "./DistanceMarker";
import InformationContainer from "./InformationContainer";
import Icon from "../Common/Icon";
import { AlertConfirmDialog } from "../Dialog/Dialog";
import { classes } from "../../utils/dom";
import I18n from "../../language/i18n";
import type { GMMarker, LatLng } from "../../flowTypes";
import {
  appPosToMapPos,
  calculateAreaSize,
  getBounds,
  latLngToAppPos,
} from "../../utils/Map";

import "./style.css";


type Props = {
  onChange: (...args: Array<any>) => any;
  polygon?: Array<LatLng> | null | undefined;
  polygonDrawing: boolean;
  center?: LatLng | null | undefined;
  defaultActiveTool?: string;
  showSearch?: boolean;
  hideMarkers?: boolean;
  onHideMarkers?: () => void;
  onChangeTool?: (arg0: string) => void;
  hideToolUi?: boolean;
  hideDistanceMarkers?: boolean;
  onHideDistanceMarkers?: () => void;
  map: google.maps.Map;
};
type State = {
  polygonMarkers: Array<LatLng>;
  centerPos: Record<string, any> | null;
  selectedMarker: Record<string, any> | null;
  activeTool: string;
  draggedMarker: LatLng | null | undefined;
  draggedMarkerPosition: LatLng | null | undefined;
  draggedMarkerStartPosition: LatLng | null | undefined;
  confirm: Record<string, any> | null;
};
type Action = {
  do: () => void;
  undo: () => void;
};
const MARKER_Z_INDEX = 2000000001; // more than the drawing manager's overlay

export default class PolygonAndMarkerPlacement extends React.PureComponent<
  Props,
  State
> {
  state = {
    polygonMarkers: this.props.polygon ? [...this.props.polygon] : [],
    centerPos: this.props.center || null,
    selectedMarker: null,
    activeTool: this.props.defaultActiveTool
      ? this.props.defaultActiveTool
      : "draw",
    draggedMarker: null,
    draggedMarkerPosition: null,
    draggedMarkerStartPosition: null,
    confirm: null,
  };

  actions: Array<Action> = [];
  currentActionIndex = -1;
  measureMarker = {
    path: (window as any).google.maps.SymbolPath.CIRCLE,
    scale: 8,
    fillColor: "#fff",
    fillOpacity: 1,
    strokeWeight: 2,
  };

  mapClickListener?: ((...args: Array<any>) => any) | void;

  // componentWillUpdate (nextProps: Props) {
  //   if (!isEqual(this.props.center, nextProps.center)) {
  //     this.setState({centerPos: appPosToLatLng(nextProps.center)})
  //   }
  // }

  componentDidMount() {
    document.addEventListener("keyup", this.onKeyUp);
    this.mapClickListener = (window as any).google.maps.event.addListener(
      this.props.map,
      "click",
      this.onMapClick,
    );
  }

  onMapClick = (e: any) => {
    if (this.state.activeTool === "draw") {
      this.addAction(this.actionSetCenterMarker(latLngToAppPos(e.latLng)));
    }
  };

  onKeyUp = (e: KeyboardEvent) => {
    if (
      this.state.activeTool === "draw" ||
      this.state.activeTool === "measure"
    ) {
      if (e.key === "z" && e.ctrlKey) {
        this.undo();
      } else if (
        (e.key === "y" && e.ctrlKey) ||
        (e.key === "Z" && e.ctrlKey && e.shiftKey)
      ) {
        this.redo();
      } else if (e.key === "Delete") {
        this.deleteSelectedMarker();
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onKeyUp);
    if (this.mapClickListener) {
      (window as any).google.maps.event.removeListener(this.mapClickListener);
    }
  }

  render() {
    return [
      this.props.polygonDrawing &&
      (this.state.activeTool === "draw" ||
        this.state.activeTool === "measure")
        ? (
          <DrawingManager
            drawingMode={(window as any).google.maps.drawing.OverlayType.MARKER}
            options={{
              drawingControl: false,
              markerOptions: {
                clickable: true,
              },
            }}
            onMarkerComplete={this.onMarkerComplete.bind(this) as any}
            ref="drawing"
            key="drawing"
          />
        )
        : null,
      this.renderPolygon(),
      ...this.renderPolygonMarkers(),
      this.renderCenterMarker(),
      ...this.renderDistanceMarkers(),
      ...this.renderControls(),
      // $FlowFixMe
      <AlertConfirmDialog
        {...this.state.confirm}
        show={!!this.state.confirm}
        key="alert"
      />,
    ];
  }

  onMarkerComplete(marker: GMMarker) {
    marker.setMap(null);
    this.addAction(
      this.actionAddPolygonMarker(latLngToAppPos(marker.getPosition())),
    );
  }

  renderPolygon() {
    if (!this.props.polygonDrawing) {
      return null;
    }

    const polygonMarkers = this.state.polygonMarkers;

    if (polygonMarkers.length > 1) {
      if (this.state.activeTool === "measure") {
        return (
          <Polyline
            path={polygonMarkers.map(marker =>
              appPosToMapPos(this.getMarkerPosition(marker)),
            )}
            key="polyline"
            options={measurePolylineOptions}
          />
        );
      } else {
        return (
          <Polygon
            path={polygonMarkers.map(marker =>
              appPosToMapPos(this.getMarkerPosition(marker)),
            )}
            key="polygon"
            options={fieldPolygonOptions}
          />
        );
      }
    }

    return null;
  }

  renderPolygonMarkers() {
    if (!this.props.polygonDrawing) {
      return [];
    }

    if (
      this.state.centerPos == null &&
      this.state.polygonMarkers.length === 0
    ) {
      return [];
    }

    return this.state.polygonMarkers.map((marker, i) => (
      <Marker
        icon={this.state.activeTool === "measure" ? (this.measureMarker as any) : null}
        position={appPosToMapPos(this.getMarkerPosition(marker))}
        zIndex={MARKER_Z_INDEX}
        clickable={true}
        draggable={true}
        onDragStart={() => {
          this.setState({
            draggedMarker: marker,
            draggedMarkerPosition: { ...marker },
            draggedMarkerStartPosition: { ...marker },
          });
        }}
        onDrag={m => {
          this.setState({
            draggedMarkerPosition: latLngToAppPos(m.latLng as any),
          });
        }}
        onDragEnd={() => {
          if (
            this.state.draggedMarker &&
            this.state.draggedMarkerPosition &&
            this.state.draggedMarkerStartPosition
          ) {
            this.addAction(
              this.actionDragPolygonMarker(
                marker,
                this.state.draggedMarkerStartPosition,
                this.state.draggedMarkerPosition,
              ),
            );
          }
          this.setState({
            draggedMarker: null,
            draggedMarkerPosition: null,
          });
        }}
        onMouseDown={() => {
          if (this.state.selectedMarker !== marker) {
            this.addAction(this.actionSelectPolygonMarker(marker));
          }
        }}
        key={"polygon-marker-" + i}
      />
    ));
  }

  renderCenterMarker() {
    const position = this.getCenterPosition();

    if (position && this.state.activeTool !== "measure") {
      return (
        <PinPositionMarker
          position={appPosToMapPos(position)}
          key="marker"
          onDragEnd={m =>
            this.addAction(this.actionSetCenterMarker(latLngToAppPos(m.latLng)))
          }
          zIndex={MARKER_Z_INDEX}
          draggable={true}
          clickable={true}
        />
      );
    } else {
      return null;
    }
  }

  renderDistanceMarkers() {
    const polygonMarkers = this.state.polygonMarkers;

    return polygonMarkers.length >= 2
      ? polygonMarkers.map((m, i) => {
        if (polygonMarkers.length === 2 && i === 1) {
          // this would be a duplicate marker
          return null;
        }
        if (
          this.state.activeTool === "measure" &&
            i === polygonMarkers.length - 1
        ) {
          // this would be a marker between first and last point
          return null;
        }

        const start = this.getMarkerPosition(m);
        const end = this.getMarkerPosition(
          polygonMarkers[(i + 1) % polygonMarkers.length],
        );

        return (
          <DistanceMarker
            key={"distance-" + i}
            start={start}
            end={end}
            map={this.props.map}
          />
        );
      })
      : [];
  }

  renderControls() {
    return [
      <MapControl position={TOP_LEFT} key="controls1" map={this.props.map}>
        {this.props.showSearch
          ? (
            <ControlContainer className="map-toolbar-container polygon-marker-placement-toolbar-search">
              <MapGeoSearch map={this.props.map} />
            </ControlContainer>
          )
          : null}
      </MapControl>,
      <MapControl
        position={LEFT_TOP}
        key="controls2"
        mergedContainerClassName="polygon-marker-placement-undo-redo-toolbar position-bottom-center"
        style={{ order: 1 }}
        map={this.props.map}
      >
        {this.props.polygonDrawing &&
        (this.state.activeTool === "draw" ||
          this.state.activeTool === "measure")
          ? (
            <ControlContainer className="map-toolbar-container polygon-marker-placement-toolbar buttons">
              <ControlButton
                onClick={this.undo.bind(this)}
                disabled={!this.canUndo()}
                title={I18n.t("undo")}>
                <Icon iconType="fa" name="undo" />
              </ControlButton>
              <ControlButton
                onClick={this.redo.bind(this)}
                disabled={!this.canRedo()}
                title={I18n.t("redo")}>
                <Icon iconType="fa" name="redo" />
              </ControlButton>
              <ControlButton
                onClick={this.reset.bind(this)}
                disabled={
                  this.actions.length === 0 &&
                this.state.polygonMarkers.length === 0 &&
                !this.state.centerPos
                }
                title={I18n.t("polygon.reset")}
                className="reset">
                <Icon name="ios-trash-outline" />
              </ControlButton>
            </ControlContainer>
          )
          : null}
      </MapControl>,
      <MapControl position={RIGHT_BOTTOM} key="controls3" mergeControls={false} map={this.props.map}>
        {this.props.polygonDrawing && !this.props.hideToolUi
          ? (
            <ControlContainer className="map-toolbar-container polygon-marker-placement-toolbar polygon-tools vertical">
              <ControlButton
                className={classes(
                  "drawing-mode",
                  "fixed-width",
                  this.state.activeTool === "draw" && "active",
                )}
                onClick={() =>
                  this.changeTool(
                    this.state.activeTool === "draw" ? "pan" : "draw",
                  )
                }
                title={I18n.t("polygon.drawingMode")}>
                <Icon iconType="fal" name="draw-polygon" />
                <Icon iconType="fal" name="plus" />
              </ControlButton>
              <ControlButton
                className={classes(
                  "measure-mode",
                  "fixed-width",
                  this.state.activeTool === "measure" && "active",
                )}
                onClick={() =>
                  this.changeTool(
                    this.state.activeTool === "measure" ? "pan" : "measure",
                  )
                }
                title={I18n.t("polygon.measureMode")}>
                <Icon iconType="fal" name="ruler" />
              </ControlButton>
              <ControlButton
                className={classes(this.props.hideMarkers && "active", "fixed-width")}
                onClick={this.props.onHideMarkers}
                title={I18n.t("polygon.hideMarkers")}>
                <Icon
                  iconType="fal"
                  name={
                    this.props.hideMarkers ? "map-marker-slash" : "map-marker"
                  }
                />
              </ControlButton>
              <ControlButton
                className={classes(
                  "hide-distance-markers",
                  "fixed-width",
                  !this.props.hideDistanceMarkers && "active",
                )}
                onClick={this.props.onHideDistanceMarkers}
                title={I18n.t("polygon.hideDistanceMarkers")}>
                <span>m</span>
              </ControlButton>
            </ControlContainer>
          )
          : null}
      </MapControl>,
      <MapControl
        position={LEFT_TOP}
        key="controls4"
        style={{ order: 3, marginLeft: "20px" }}
        map={this.props.map}
      >
        {this.props.polygonDrawing &&
        (this.state.activeTool === "draw" ||
          this.state.activeTool === "measure")
          ? (
            <ControlContainer className="map-toolbar-container polygon-marker-placement-toolbar info">
              <InformationContainer
                stack={this.state.polygonMarkers.map(m =>
                  this.getMarkerPosition(m),
                )}
                ui={{
                  isDrawing:
                  this.state.activeTool === "draw" ||
                  this.state.activeTool === "measure",
                  drawing:
                  this.state.activeTool === "draw" ? "polygon" : "polyline",
                }}
                center={this.getCenterPosition()}
              />
            </ControlContainer>
          )
          : null}
      </MapControl>,
    ];
  }

  changeTool(tool: string) {
    if (
      tool !== "draw" &&
      tool !== "measure" &&
      this.state.activeTool === "draw" &&
      this.state.polygonMarkers.length > 2
    ) {
      this.setState({
        confirm: {
          title: I18n.t("maps.deletePolygon"),
          buttons: [
            { label: I18n.t("cancel"), value: "cancel" },
            {
              label: I18n.t("delete"),
              value: "delete",
              className: "btn-danger",
            },
          ],
          onClose: result => {
            if (result === "delete") {
              this._changeTool(tool);
            }
            this.setState({ confirm: null });
          },
        },
      });
    } else {
      this._changeTool(tool);
    }
  }

  _changeTool(tool: string) {
    this.setState({ activeTool: tool });
    if (
      tool !== "draw" &&
      tool !== "measure" &&
      (this.state.activeTool === "draw" || this.state.activeTool === "measure")
    ) {
      this.actions = [];
      this.currentActionIndex = -1;
      this.setState(
        { polygonMarkers: [], centerPos: null, selectedMarker: null },
        () => this.onChange(),
      );
    }

    if (this.props.onChangeTool) {
      this.props.onChangeTool(tool);
    }
  }

  getMarkerPosition(marker: LatLng) {
    return this.state.draggedMarker === marker &&
      this.state.draggedMarkerPosition
      ? this.state.draggedMarkerPosition
      : marker;
  }

  reset() {
    this.addAction(this.actionReset());
  }

  deleteSelectedMarker() {
    if (this.state.selectedMarker) {
      this.addAction(this.actionDeletePolygonMarker(this.state.selectedMarker));
    }
  }

  onChange() {
    if (this.props.onChange) {
      this.props.onChange(this.getValue());
    }
  }

  getValue() {
    return {
      areaSize: this.getAreaSize(),
      polygon: this.getPolygonCoordinates(),
      center: this.getCenterPosition(),
    };
  }

  getCenterPosition() {
    const polygonMarkers = this.state.polygonMarkers;
    let position = this.state.centerPos;

    // if the user set/dragged the center marker then we have a position, so don't override it
    if (!position && polygonMarkers.length >= 3) {
      const bounds = getBounds(this.getPolygonCoordinates());
      position = latLngToAppPos(bounds.getCenter());
    } else if (!position && polygonMarkers.length === 1) {
      // the first marker is the center unless we place more markers
      position = polygonMarkers[0];
    }
    return position || null;
  }

  getPolygonCoordinates(): Array<LatLng> {
    // we treat the first marker as the center
    if (!this.state.centerPos && this.state.polygonMarkers.length === 1) {
      return [];
    } else {
      return this.state.polygonMarkers.map(m => this.getMarkerPosition(m));
    }
  }

  getAreaSize() {
    return Number(
      calculateAreaSize(this.state.polygonMarkers).hectare.toFixed(2),
    );
  }

  addAction(action: Action) {
    if (this.actions.length > this.currentActionIndex + 1) {
      // remove actions past currentActionIndex
      this.actions.splice(
        this.currentActionIndex + 1,
        this.actions.length - this.currentActionIndex - 1,
      );
    }
    this.actions = [...this.actions, action];
    this.currentActionIndex++;
    action.do();
  }

  undo() {
    if (this.canUndo()) {
      this.actions[this.currentActionIndex--].undo();
    }
  }

  canUndo() {
    return this.currentActionIndex >= 0;
  }

  redo() {
    if (this.canRedo()) {
      this.actions[++this.currentActionIndex].do();
    }
  }

  canRedo() {
    return this.currentActionIndex + 1 < this.actions.length;
  }

  actionSetCenterMarker(newPosition: LatLng): Action {
    const oldPosition = this.state.centerPos;
    const isFirstMarker =
      oldPosition == null && this.state.polygonMarkers.length === 1;
    const firstMarkerAction = this.actionDeletePolygonMarker(
      this.state.polygonMarkers[0],
    );
    return {
      do: () => {
        if (isFirstMarker) {
          firstMarkerAction.do();
        }
        this.setState(
          {
            centerPos: newPosition,
          },
          this.onChange.bind(this),
        );
      },
      undo: () => {
        if (isFirstMarker) {
          firstMarkerAction.undo();
        }
        this.setState(
          {
            centerPos: oldPosition,
          },
          this.onChange.bind(this),
        );
      },
    };
  }

  actionDragPolygonMarker(
    marker: LatLng,
    oldPosition: LatLng,
    newPosition: LatLng,
  ): Action {
    return {
      do: () => {
        Object.assign(marker, newPosition);
        this.forceUpdate(this.onChange.bind(this));
      },
      undo: () => {
        Object.assign(marker, oldPosition);
        this.forceUpdate(this.onChange.bind(this));
      },
    };
  }

  actionSelectPolygonMarker(marker: LatLng): Action {
    const oldSelectedMarker = this.state.selectedMarker;
    return {
      do: () => {
        this.setState(
          {
            selectedMarker: marker,
          },
          this.onChange.bind(this),
        );
      },
      undo: () => {
        this.setState(
          {
            selectedMarker: oldSelectedMarker,
          },
          this.onChange.bind(this),
        );
      },
    };
  }

  actionAddPolygonMarker(marker: LatLng): Action {
    const oldSelectedMarker = this.state.selectedMarker;
    return {
      do: () => {
        this.setState(
          {
            polygonMarkers: [...this.state.polygonMarkers, marker],
            selectedMarker: marker,
          },
          this.onChange.bind(this),
        );
      },
      undo: () => {
        this.setState(
          {
            polygonMarkers: without(this.state.polygonMarkers, marker),
            selectedMarker: oldSelectedMarker,
          },
          this.onChange.bind(this),
        );
      },
    };
  }

  actionDeletePolygonMarker(marker: LatLng): Action {
    const index = findIndex(this.state.polygonMarkers, m => m === marker);
    return {
      do: () => {
        const newPolygonMarkers = without(this.state.polygonMarkers, marker);
        this.setState(
          {
            polygonMarkers: newPolygonMarkers,
            selectedMarker:
              index > 0
                ? this.state.polygonMarkers[index - 1]
                : newPolygonMarkers.length > 0
                  ? newPolygonMarkers[0]
                  : null,
          },
          this.onChange.bind(this),
        );
      },
      undo: () => {
        this.setState(
          {
            polygonMarkers: [
              ...this.state.polygonMarkers.slice(0, index),
              marker,
              ...this.state.polygonMarkers.slice(index),
            ],
            selectedMarker: marker,
          },
          this.onChange.bind(this),
        );
      },
    };
  }

  actionReset(): Action {
    const oldPolygonMarkers = this.state.polygonMarkers;
    const selectedMarker = this.state.selectedMarker;
    const oldCenterPos = this.state.centerPos;
    return {
      do: () => {
        this.setState(
          {
            polygonMarkers: [],
            centerPos: null,
            selectedMarker: null,
          },
          this.onChange.bind(this),
        );
      },
      undo: () => {
        this.setState(
          {
            polygonMarkers: oldPolygonMarkers,
            centerPos: oldCenterPos,
            selectedMarker: selectedMarker,
          },
          this.onChange.bind(this),
        );
      },
    };
  }
}

import React from "react";
import Text from "../../Common/Text";
import * as constants from "../../../styles/style";

const FieldSizeRenderer = ({ value }) => {
  return value
    ? <Text
      style={{
        float: "right",
        ...constants.styles.small,
      }}>{value}</Text>
    : null;
};
export default React.memo(FieldSizeRenderer);

import React from "react";
import DataRow from "./Helpers/DataRow";
import I18n from "../../../language/i18n";
import Text from "../../Common/Text";
import { FJAPPLERED } from "../../../styles/style";
import { getColorForValue } from "../utils";
import { ContentRendererProps } from "./BoniturContentRenderersType";

interface BooleanRendererProps extends ContentRendererProps {
  isBooleanColorSchemes?: boolean;
}

const BooleanRenderer = ({ element: { label, name, options }, value, valueOnly, isBooleanColorSchemes }: BooleanRendererProps) => {
  const boolValue = value ? I18n.t("yes") : I18n.t("no");
  const changedColor = getColorForValue(options?.colorSchemes, { [name]: value });
  const style = { color: changedColor };

  if (valueOnly && name === "weed" && value) {
    return <Text
      style={{
        color: FJAPPLERED,
      }}
    >
      {boolValue}
    </Text>;
  }
  return <DataRow label={label} value={boolValue} valueOnly={valueOnly} style={isBooleanColorSchemes ? style : {} }/>;
};

export default React.memo(BooleanRenderer);

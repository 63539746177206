import React from "react";

import "./TableListViewSwitch.css";
import Icon from "./Icon";

import { TableListViewState } from "../../flowTypes";
import I18n from "../../language/i18n";

type Props = {
  currentState: TableListViewState;
  onStateChange: (newState: TableListViewState) => void;
  availableStates?: TableListViewState[];
};

export const TableListViewSwitch = (props: Props) => {
  const {
    currentState,
    onStateChange,
    availableStates = [TableListViewState.Classic, TableListViewState.Table],
  } = props;
  const nextState = getNextState(currentState, availableStates);

  return (
    <div>
      {getSwitches(availableStates, currentState, () => onStateChange(nextState))}
    </div>
  );
};

const getNextState = (currentState: TableListViewState, availableStates: TableListViewState[]): TableListViewState => {
  const currentIndex = availableStates.indexOf(currentState);
  return availableStates[(currentIndex + 1) % availableStates.length];
};

const getSwitches = (availableStates: TableListViewState[], currentState: TableListViewState, onClick) => {
  const defaultClassname: string = "top-bar-button table-list-view-switch";
  const activeClassname: string = defaultClassname + " active";

  return availableStates.map((state, idx) => {
    switch (state) {
    case TableListViewState.Classic:
      return (
        <div
          key={idx}
          className={
            currentState === TableListViewState.Classic
              ? activeClassname
              : defaultClassname
          }
          onClick={onClick}
        >
          <Icon name="list" iconType="fal" />
          <span>{I18n.t("list")}</span>
        </div>
      );
    case TableListViewState.Table:
      return (
        <div
          key={idx}
          className={
            currentState === TableListViewState.Table
              ? activeClassname
              : defaultClassname
          }
          onClick={onClick}
        >
          <Icon name="th-list" iconType="fal" />
          <span>{I18n.t("table")}</span>
        </div>
      );
    case TableListViewState.Gallery:
      return (
        <div
          key={idx}
          className={
            currentState === TableListViewState.Gallery
              ? activeClassname
              : defaultClassname
          }
          onClick={onClick}
        >
          <Icon name="image" iconType="fa" />
          <span>{"Gallery"}</span>
        </div>
      );
    default:
      return null;
    };
  });
};

import React from 'react';
import DatePicker from 'rc-calendar/lib/Picker';
import Calendar from 'rc-calendar';
import moment from 'moment';
import { get } from 'lodash-es';

import { toDate } from 'farmerjoe-common';

import I18n, { getCurrentLocaleData } from '../../language/i18n';
import { defaultDateFormat } from '../../tcomb/utils/options';

import * as constants from '../../styles/style';


const BoniturCalendar = (props) => {
  const { minimumDate, maximumDate, format, disabled = false, label, onChange, value } = props;

  const disabledDate = (current) => {
    const currentDateValue = current && current.valueOf();
    if ((minimumDate && currentDateValue < moment(minimumDate).valueOf()) ||
      (minimumDate && currentDateValue < moment(minimumDate).valueOf())) {
      return true;
    }

    if (
      (maximumDate &&
        currentDateValue >
        moment(maximumDate).valueOf()) ||
      (maximumDate &&
        currentDateValue >
        moment(maximumDate).valueOf()
      )
    ) {
      return true;
    }
    return false;
  };


  let formattedValue = defaultDateFormat(toDate(value));

  if (format) {
    formattedValue = format(toDate(value));
  }

  const _label = label ? <span>{label}</span> : null;

  let container: any = null;

  if (!formattedValue) {
    formattedValue = I18n.t('datePleaseSelect');
  }

  if (disabled) {
    return (
      <div style={{ marginBottom: 25 }}>
        {_label}
        <span style={{ ...constants.styles.stdSize, ...{ marginTop: 0 } }}>
          {formattedValue}
        </span>
      </div>
    );
  }
  const calendar = (
    <Calendar
      showWeekNumber={false}
      locale={get(getCurrentLocaleData(), 'calendar.default')}
      showToday
      showDateInput={false}
      format={'YYYY-MM-DD'}
      showOk={false}
      disabledDate={disabledDate}
    />
  );

  container = (
    <div className="modal-date">
      <div className={'mb-1'} key={0}>
        {_label}
      </div>
      <DatePicker
        placement={'bottom'}
        align={{ points: ['tc', 'bc'] }}
        calendar={calendar}
        defaultValue={
          value != null
            ? moment(toDate(value)).locale(I18n.locale)
            : null
        }
        onChange={date => onChange(date.toDate())}>
        {({ value }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                paddingBottom: 0,
                borderWidth: 1,
                borderColor: constants.FJNEWGREEN,
                borderRadius: 5,
                backgroundColor: constants.FJNEWGREEN,
                cursor: 'pointer',
                // backgroundColor: '#EEE'
              }}>
              <span
                style={{ ...{ color: '#FFF', padding: 3, paddingTop: 6 } }}>
                {formattedValue}
              </span>
            </div>
          );
        }}
      </DatePicker>
    </div>
  );

  return container;
};

export default BoniturCalendar;

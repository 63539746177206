import React, { useEffect } from "react";
import { FJBROWN } from "../../../styles/style";
import Separator from "../../../components/Common/Separator";
import { calculate } from "farmerjoe-common/lib/utils/math";
import "./style.css";

const MathCalculationRenderer = ({ config, formik }) => {
  const { name: fieldName, label, formClass } = config;
  const { setFieldValue } = formik;
  const {
    options: {
      calculation,
      valueLabel,
      withSeparator,
      previewInForm = true,
      dependsOn,
    },
  } = config;
  const { values: formikValues } = formik;
  const result = calculate(calculation, formikValues);
  const roundedResult = Math.round(result * 100) / 100 || 0;

  useEffect(() => {
    setFieldValue(fieldName, roundedResult);
  }, [formikValues, fieldName, setFieldValue, result, roundedResult]);

  if(!previewInForm) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span className={formClass} style={{ color: FJBROWN }}>
            {label}
          </span>
        </div>
        <div>
          <span style={{ color: FJBROWN, fontWeight: "bold" }}>
            {!isNaN(roundedResult) ? Number(roundedResult).toLocaleString() : "-"}{" "}
            {valueLabel}
          </span>
        </div>
      </div>
      {withSeparator ? <Separator style={{ marginTop: 15 }} /> : null}
    </div>
  );
};

export default React.memo(MathCalculationRenderer);

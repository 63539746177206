import React from 'react';
import Modal from 'react-bootstrap-modal';

import I18n from '../../language/i18n';
import { classes } from '../../utils/dom';
import './style.css';

type DialogPropsBase = {
  className?: string;
  children?: React.ReactNode;
  show: boolean;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  headerProps?: {
    closeButton?: boolean;
    onClose?: (...args: Array<any>) => any;
  };

  backdrop?: any;
  keyboard?: any;
  transition?: any;
  attentionClass?: any;
  container?: any;
  onEnter?: any;
  onEntering?: any;
  onEntered?: any;
  onExit?: any;
  onExiting?: any;
  onExited?: any;
};

type DialogProps = DialogPropsBase & {
  onClose?: (value: null) => void;
  dialogClassName?: string;
};

// prevent the clicks from bubbling outside of the Dialog, this causes problems
const stopPropagation = e => e.stopPropagation();

export default class Dialog extends React.Component<DialogProps> {
  render() {
    const {
      children,
      headerProps = { closeButton: true },
      title = '',
      onClose,
      footer,
      ...restProps
    } = this.props;

    const { closeButton, ..._headerProps } = headerProps;
    return (
      <Modal
        enforceFocus={false}
        backdrop="static"
        {...restProps}
        onHide={() => onClose && onClose(null)}>
        {title != null && (
          <Modal.Header {..._headerProps}>
            <Modal.Title onClick={stopPropagation}>{title}</Modal.Title>
            {closeButton
              ? (
                <button
                  className="close"
                  onClick={() => onClose && onClose(null)}>
                  <i className="fa fa-times" />
                </button>
              )
              : null}
          </Modal.Header>
        )}
        <Modal.Body onClick={stopPropagation}>{children}</Modal.Body>
        {footer != null && (
          <Modal.Footer onClick={stopPropagation}>{footer}</Modal.Footer>
        )}
      </Modal>
    );
  }
}

type Button = {
  value: any;
  label: React.ReactNode;
  className?: string;
  disabled?: boolean;
};
type ConfirmDialogProps = DialogPropsBase & {
  buttons?: Array<Button>;
  onClose: (value: any) => void;
};

export class ConfirmDialog extends React.Component<ConfirmDialogProps> {
  render() {
    const {
      children,
      title = '',
      buttons = [
        { value: 'no', label: I18n.t('cancel'), className: 'btn-secondary' },
        { value: 'yes', label: I18n.t('yes'), className: 'btn-primary' },
      ],
      onClose,
      ...restProps
    } = this.props;

    return (
      <Dialog
        footer={buttons.map(
          ({ value, label, className = '', ...restProps }, i) => (
            <button
              className={'btn ' + className}
              key={i}
              onClick={() => onClose(value)}
              {...restProps}>
              {label}
            </button>
          ),
        )}
        title={title}
        onClose={onClose}
        {...restProps}>
        {children}
      </Dialog>
    );
  }
}

export function AlertDialog({ className, ...restProps }: DialogProps) {
  return (
    <Dialog className={classes('alert-dialog', className)} {...restProps} />
  );
}

export function AlertConfirmDialog({
  className,
  ...restProps
}: ConfirmDialogProps) {
  return (
    <ConfirmDialog
      className={classes('alert-dialog', className)}
      {...restProps}
    />
  );
}

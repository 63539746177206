import React from "react";
import "./style.css";
import { classes } from "../../utils/dom";

type Props = {
  count: number | string;
  className?: string;
};

const CountBadge = ({ count, className }: Props) => {
  return <span className={classes("count-badge", className)}>{count}</span>;
};

export default CountBadge;

import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import moize from "moize";

import { getColor } from "farmerjoe-common/lib/utils/Colors";
import * as fieldActions from "farmerjoe-common/lib/actions/field";
import { filters } from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { searchForFieldAmongTheUniverse } from "farmerjoe-common/lib/selectors/fields";

import MarkerWithLabel from "./MarkerWithLabel";
import FieldInfoBalloon from "../Field/FieldInfoBalloon";
import withRouter from "../Router/withRouter";
import {
  appPosToLatLng,
  getHintBalloonHorizontalPosStyle,
  getHintBalloonVerticalPosClass,
} from "../../utils/Map";
import { classes } from "../../utils/dom";
import { Field } from "../../flowTypes";

import "./style.css";

const DEFAULT_HEIGHT = 30;

const containerStyle = moize(bubbleColor => ({ color: bubbleColor }));
// borderTopColor is for the :after pseudo element to inherit
const bubbleStyle = moize(bubbleColor => ({
  backgroundColor: bubbleColor,
  borderTopColor: bubbleColor,
}));

// TODO: improve typings
type Props = {
  description: JSX.Element;
  marker: any;
  onClick: (...args: Array<any>) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  map: google.maps.Map;
  field?: Field;
};
type State = any;

class MarkerHecktar extends Component<Props, State> {
  state = {
    markerHeight: null,
    showBalloon: false,
    balloonIsClosing: false,
  };

  div = React.createRef();

  render() {
    const map = this.props.map;
    // it appears that in some fringe cases getDiv can return undefined
    if (!map.getDiv()) {
      return null;
    }

    const { description, marker, isOpen, field } = this.props;
    const activeCrop = marker.activeCrop || {};
    const bubbleColor = getColor(activeCrop.color);
    const showHint = this.state.showBalloon || isOpen;
    const balloonIsClosing = this.state.balloonIsClosing;

    // balloon position calc
    const mapWidth = map.getDiv().clientWidth;
    const mapHeight = map.getDiv().clientHeight;
    const markerPosPx = this.calculateMarkerPositionInContainer();

    const hintBalloonHorizontalPosStyle = getHintBalloonHorizontalPosStyle(
      markerPosPx ? markerPosPx.x : mapWidth / 2,
      0.5,
      mapWidth,
    );
    const hintBalloonVerticalPosClass = getHintBalloonVerticalPosClass(
      markerPosPx ? markerPosPx.y : 99999,
      // mapHeight
    );

    return (
      <MarkerWithLabel
        position={this.convertPosition(marker.position) as any}
        zIndex={
          isOpen || (balloonIsClosing as any) === "isOpen" // TODO: fix: boolean vs string comparison
            ? 102
            : showHint || (balloonIsClosing as any) === "showBalloon" // TODO: fix: boolean vs string comparison
              ? 103
              : void 0
        }
        map={this.props.map}
        noTriangle={true}
        width="auto"
        height={DEFAULT_HEIGHT}
        labelClass="marker-label field-marker">
        <FieldInfoBalloon
          isOpen={isOpen}
          className={hintBalloonVerticalPosClass}
          style={containerStyle("#fff")}
          onClick={this.onClick}
          field={field as Field}
          activeCrop={marker.activeCrop}
          hintContentStyle={{
            ...hintBalloonHorizontalPosStyle,
            transform: (this.state as any).balloonIsOpening
              ? "translateX(-50%)"
              : void 0,
          }}
          onStateChange={this.onBalloonStateChange}>
          <div className={classes("marker-bubble", isOpen ? "opened" : "")} style={bubbleStyle(bubbleColor)}>
            <div className="description">{description}</div>
          </div>
        </FieldInfoBalloon>
      </MarkerWithLabel>
    );
  }

  onBalloonStateChange = state => this.setState(state);

  onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { isOpen, onOpen, onClick } = this.props;
    if (!isOpen && onOpen) {
      onOpen();
    }
    onClick && onClick(e);
  };

  /**
   * calculate the pixel coordinates of the marker in the maps container
   */
  calculateMarkerPositionInContainer() {
    const map = this.props.map;
    const marker = this.props.marker;
    const projection = map.getProjection();
    const bounds = map.getBounds();

    if (projection && bounds) {
      const scale = Math.pow(2, map.getZoom() as number);
      const topRight = projection.fromLatLngToPoint(bounds.getNorthEast()) as any;
      const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest()) as any;
      const worldPoint = projection.fromLatLngToPoint(
        appPosToLatLng(marker.position) as any,
      ) as any;
      return new (window as any).google.maps.Point(
        (worldPoint.x - bottomLeft.x) * scale,
        (worldPoint.y - topRight.y) * scale,
      );
    }
    return null;
  }

  convertPosition = moize.simple(position => {
    return appPosToLatLng(position);
  });
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        ...fieldActions,
        filters,
      },
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, ownProps.marker.key);

  return {
    openCompany: openCompany,
    field,
  };
};

export default compose<typeof MarkerHecktar>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(MarkerHecktar);

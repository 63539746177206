import React from "react";
import I18n from "../../../language/i18n";

const CropAgeRenderer = ({ value }) => {
  function getContent() {
    if (value === undefined) {
      return "";
    }
    return I18n.t("x_days", { days: value });
  }

  return <span>{getContent()}</span>;
};

export default React.memo(CropAgeRenderer);

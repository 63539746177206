import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Icon from "../Common/Icon";
import I18n from "../../language/i18n";
import { getBounds } from "../../utils/Map";

// TODO: improve typings
type Props = {
  map: google.maps.Map;
  markers: any[];
};
type State = any;

export default class MapCenterOnMarkers extends PureComponent<Props, State> {
  static propTypes = {
    map: PropTypes.object.isRequired,
    markers: PropTypes.array.isRequired,
  };

  static get defaultProps() {
    return {
      markers: [],
    };
  }

  render() {
    return (
      <button
        title={I18n.t("company.company")}
        className="map-toolbar-button company-button fixed-width"
        onClick={() => {
          this.centerOnMapMarkers(true, true);
        }}>
        <Icon name="barn" iconType={"fj"} />
      </button>
    );
  }

  centerOnMapMarkers(animation = true, force = false) {
    const markerPositions = this.props.markers?.map(marker => marker.position);

    // Only fit to supplied markers if we've specified it & we have markers to center on
    if (markerPositions.length || force) {
      this.props.map.fitBounds(getBounds(markerPositions) as any);
    }
  }
}
